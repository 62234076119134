import React, { Component } from "react";
import "./ScreenRoot.scss";
import scroll from "../../utils/scroll";
import classnames from "classnames";
import Loader from "../loader/Loader";
import Hammer from "hammerjs";
import { ScreenPost } from "../screen-post/ScreenPost";
import ScreenPanel from "../screen-panel/ScreenPanel";

class ScreenRoot extends Component {
    state = {
        isLoading: true,
        debIndex: 0,
    };

    constructor() {
        super();

        this.container = React.createRef();
    }

    get debsArray() {
        const { debates } = this.props;
        return Object.values(debates).map(debate => debate.index);
    }

    getIndex = props => {
        const { match } = props,
            { params } = match || {},
            { debIndex } = params;

        return parseInt(debIndex, 10);
    };

    get debIndex() {
        return this.getIndex(this.props);
    }

    componentDidUpdate() {
        if (!this.fired) {
            this.setState({ isLoading: false });
            this.fired = true;
        }
    }

    loadDebate = async debIndex => {
        this.props.changeTagId(0);
        this.props.changeSentenceId(-1);
        this.props.changeCommentId("i0");

        scroll.to(0, false);

        if (isNaN(debIndex)) return null;

        const res = await this.props.getMapping(debIndex);
        const { debateId } = res || {};

        if (debateId) {
            this.props.changeDebateId(debateId);
            await this.props.fetchDebate(debateId);
        }
    };

    configSwipers() {
        const { isMobile } = this.context;
        if (!isMobile) return;

        this.hammer = Hammer(this.container.current);
        this.hammer.on("swipeleft", this.next); // remove ()
        this.hammer.on("swiperight", this.prev);
    }

    async componentDidMount() {
        this.props.changeBarMode("debate");
        this.props.changeShowBack(true);
        this.configSwipers();

        document.addEventListener("mouseup", this.mouseUp);
        this.loadDebate(this.debIndex);
    }

    componentWillReceiveProps(props) {
        const index = this.getIndex(props);

        if (isNaN(index)) return;

        if (index !== this.state.index) {
            this.setState({ index });
            this.loadDebate(index);
        }
    }

    nudge = delta => {
        const { editMode } = this.props;

        if (editMode) {
            return;
        }

        const arr = this.debsArray,
            len = arr.length;

        let index = arr.indexOf(this.debIndex);

        index += delta;

        if (index < 0) {
            index = len - 1;
        }

        if (index >= len) {
            index = 0;
        }

        this.props.history.push(`/${arr[index]}`);

        this.loadDebate();

        setTimeout(() => {
            scroll.to(0);
        }, 500);
    };

    next = () => {
        this.nudge(1);
    };

    prev = () => {
        this.nudge(-1);
    };

    componentWillUnmount() {
        document.removeEventListener("mouseup", this.mouseUp);
        this.props.changeBarMode("");
        this.props.changeShowBack(false);
    }

    renderLoader() {
        return (
            <div className="loader">
                <Loader size={40} />
            </div>
        );
    }

    renderPost() {
        const { isLoading } = this.state;

        if (isLoading) {
            return this.renderLoader();
        }

        return <ScreenPost {...this.props} />;
    }

    render() {
        const { xrayEdit } = this.props;

        const classNamePanel = classnames("panel", {
            xrayEdit,
        });

        return (
            <div className="ScreenRoot-container" ref={this.container}>
                <div className="main">{this.renderPost()}</div>
                <div className={classNamePanel}>
                    <ScreenPanel {...this.props} />
                </div>
            </div>
        );
    }
}

export default ScreenRoot;
