import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import sparkSave from "./sparkSave";
import ReduxThunk from "redux-thunk";
import states from "./sparkStates";
import createSagaMiddleware from "redux-saga";
import cycleRoot from "./sagas/cycleRoot";

export const sagaMiddleware = createSagaMiddleware();

const debdbApp = combineReducers(states);

export const initStore = (initialState = {}) => {
    const store = createStore(
        debdbApp,
        initialState,
        compose(
            applyMiddleware(sagaMiddleware, ReduxThunk, sparkSave),
            window && window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f,
        ),
    );

    sagaMiddleware.run(cycleRoot);

    return store;
};

export default initStore;
