import actions from "../sparkActions";

export default dispatch => {
    return {
        onContantFormSave: (name, phone, notes) => {
            return dispatch(
                actions.addContact(0, {
                    name,
                    phone,
                    notes,
                    flags: { course: true },
                }),
            );
        },
        onVolunteerFormSave: (name, phone, notes) => {
            return dispatch(
                actions.addContact(0, {
                    name,
                    phone,
                    notes,
                    flags: { volunteer: true },
                }),
            );
        },
    };
};
