import appState from "./appState";
import bons from "./bons";
import builder from "./builder";
import contacts from "./contacts";
import debates from "./debates";
import examples from "./examples";
import sessions from "./sessions";
import modal from "./modal";
import personas from "./personas";
import pendings from "./pendings";
import dumps from "./dumps";
import tags from "./tags";

export default (dispatch, dispatchP) => ({
    ...appState(dispatch, dispatchP),
    ...bons(dispatch, dispatchP),
    ...builder(dispatch, dispatchP),
    ...contacts(dispatch, dispatchP),
    ...debates(dispatch, dispatchP),
    ...examples(dispatch, dispatchP),
    ...sessions(dispatch, dispatchP),
    ...modal(dispatch, dispatchP),
    ...personas(dispatch, dispatchP),
    ...pendings(dispatch, dispatchP),
    ...dumps(dispatch, dispatchP),
    ...tags(dispatch, dispatchP),
});
