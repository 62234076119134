// @flow
import React, { Component } from "react";
import "./ScreenPanel.scss";
import contextTypes from "../contextTypes";
import NewBon from "../new-bon/NewBon";
import Icon from "../icons/Icon";
import components from "../../config/client.components";

export class ScreenPanel extends Component {
    state = {};

    onIconClick = item => {
        const { id } = item;

        this.props.changeOverlay(id);
    };

    goBack = () => {
        this.props.history.goBack();
    };

    renderAddSection() {
        const { editMode } = this.props;

        if (!editMode) return null;

        return <NewBon {...this.props} />;
    }

    renderNav() {
        return (
            <div className="nav">
                <Icon
                    big={true}
                    isMaterial={true}
                    name="close"
                    onClick={this.goBack}
                />
            </div>
        );
    }

    render() {
        const Stacks = components.stacks;

        return (
            <div className="ScreenPanel-container">
                {this.renderNav()}
                <div className="body">
                    {this.renderAddSection()}
                    <Stacks />
                </div>
            </div>
        );
    }
}

ScreenPanel.contextTypes = contextTypes;

export default ScreenPanel;
