// @flow
import React, { Component } from "react";
import "./PanelModal.scss";
import contextTypes from "../contextTypes";
import Modal from "../modal/Modal";
import components from "../../config/client.components";

export class PanelModal extends Component {
    state = {};

    render() {
        const { i18n } = this.context;
        const Stacks = components.stacks;

        return (
            <Modal
                title={i18n.panel}
                onClose={this.props.onClose}
                allowScroll={true}>
                <div className="PanelModal-container">
                    <Stacks />
                </div>
            </Modal>
        );
    }
}

PanelModal.contextTypes = contextTypes;

export default PanelModal;
