import config from "../config";
import { getString, setString } from "./storage";

let token = getString("token");

export const setToken = _token => {
    token = _token;
    setString("token", token);
};

export const _fetch = (path, method = "GET", body) => {
    let url = path;
    //
    // const httpExists = url.indexOf("http") === 0;

    // if (!httpExists) {
    url = config.server.DOMAIN + url;
    // }

    let options = {
        method,
    };

    options.headers = {
        "Content-Type": "application/json",
        Authorization: token,
        locale: config.locale,
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    return fetch(url, options).then(res => {
        return res.json();
    });
};

export const sendBeacon = (path, body = {}) => {
    let url = `${config.server.DOMAIN}${path}/${token}`;
    navigator.sendBeacon(url);
};

export default {
    setToken,
    _fetch,
    sendBeacon,
};
