export default {
    DOMAIN: "https://gentle-peak-86956.herokuapp.com",
    FIREBASE:
        "https://console.firebase.google.com/u/0/project/giro-2/database/giro-2/data/",
    LOG: false,
    SIGNIN_METHOD: "email",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyBwJDxqghPc8zA8DzcyMPgXr4THyzag9ug",
        authDomain: "giro-2.firebaseapp.com",
        databaseURL: "https://giro-2.firebaseio.com",
        consoleURL:
            "https://console.firebase.google.com/u/0/project/giro-2/database/giro-2/data",
        projectId: "giro-2",
        storageBucket: "giro-2.appspot.com",
        messagingSenderId: "827671298889",
    },
};
