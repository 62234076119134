import { put, take, select } from "redux-saga/effects";
import actions from "../sparkActions";
import * as appState from "../selectors/appState";
import { isMobile } from "../../utils/screen";
import { getDebates } from "../../utils/firebase-rest";

function* root() {
    try {
        while (true) {
            yield take("START");

            const mobile = isMobile();
            const overlay = yield select(appState.overlay);
            const initialDebates = yield getDebates();
            yield put(actions.setDebates(initialDebates));
            yield put(actions.getDebates());
            yield put(actions.getPersonas());
            yield put(actions.getExamples(overlay));
            yield put(actions.getTags("lofs"));
            yield put(actions.getTags("nvcs"));
            yield put(actions.patchAppState({ mobile }));
        }
    } finally {
    }
}

export default root;
