import site from "./sites/il";
import components from "./client.components";
import globals from "../utils/globals";

const currentLocale = "il";
const currentProduct = "admin";
const dev = process.env.REACT_APP_STAGE === "dev";
const env = dev ? "dev" : "prod";

globals.defaults = site.defaults;

export default {
    env,
    get i18n() {
        return site.i18n;
    },
    get defaultDebate() {
        return site.defaults.defaultDebate();
    },
    get defaultComment() {
        return site.defaults.defaultComment();
    },
    get defaults() {
        return site.defaults.defaults();
    },
    get components() {
        return components;
    },
    get templates() {
        return site.templates;
    },
    get server() {
        const server = dev ? site.staging : site.server;
        return server;
    },
    get locale() {
        return currentLocale;
    },
    get product() {
        return currentProduct;
    },
};
