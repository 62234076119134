// @flow
import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";
import contextTypes from "../contextTypes";
import Icon from "../icons/Icon";
import classnames from "classnames";
import { isMobile } from "../../utils/screen";

const noop = () => {};

export class Modal extends Component {
    static defaultProps = {
        onClose: noop,
    };

    constructor() {
        super();
        this.modalContainer = React.createRef();
        this.modal = React.createRef();
    }

    state = {};

    click = ev => {
        if (ev.target === this.modalContainer.current) {
            this.props.onClose();
        }
    };

    keyDown = ev => {
        if (ev.which === 27) {
            this.props.onClose();
        }
    };

    get bodyClasses() {
        return document.querySelector("body").classList;
    }

    componentDidMount() {
        const { allowScroll } = this.props;

        document.addEventListener("click", this.click);
        document.addEventListener("keydown", this.keyDown);

        if (!allowScroll || !isMobile()) {
            this.bodyClasses.add("no-scroll");
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.click);
        document.removeEventListener("keydown", this.keyDown);
        this.bodyClasses.remove("no-scroll");
    }

    renderInner() {
        const { isMobile } = this.context;
        const { title } = this.props;

        const className = classnames("modal fadeInUp", {
            animated: isMobile,
        });

        return (
            <div className="Modal-container" ref={this.modalContainer}>
                <div className={className} ref={this.modal}>
                    <div className="header">
                        <div className="title">{title}</div>
                        <Icon
                            name="close"
                            className="close"
                            onClick={this.props.onClose}
                        />
                    </div>
                    <div className="content">{this.props.children}</div>
                </div>
            </div>
        );
    }

    render() {
        return ReactDOM.createPortal(
            this.renderInner(),
            document.getElementById("modal"),
        );
    }
}

Modal.contextTypes = contextTypes;

export default Modal;
