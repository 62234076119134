import actions from "../sparkActions";
import globals from "../../utils/globals";

export default (dispatch, dispatchP) => {
    return {
        getDebates: () => {
            return dispatch(actions.getDebates());
        },
        saveDeb: deb => {
            return dispatch(actions.patchDebate(deb.id, deb));
        },
        addDebate: debate => {
            return dispatch(actions.addDebate(0, debate));
        },
        promoteDebate: pending => {
            return dispatchP({
                type: "PROMOTE_DEBATE",
                pending,
            });
        },
        ccDebate: (debId, content, marks) => {
            return dispatchP({
                type: "CC_DEBATE",
                debId,
                content,
                marks,
            });
        },
        patchDebateProgress: (debId, change) => {
            return dispatch(actions.patchDebateProgress(debId, change));
        },
        patchDebateRoot: (debId, change) => {
            if (change.dateDebate && change.dateDebate.length === 10) {
                change.dateDebate += " 10:00:00";
            }

            if (change.cardImageRatio) {
                change.cardImageRatio = parseFloat(change.cardImageRatio);
            }

            return dispatch(actions.patchDebateRoot(debId, change));
        },
        fetchDebate: debId => {
            dispatch(actions.getDebate(debId));
            dispatch(actions.getBons(debId));
        },
        getMapping: debIndex => {
            return globals.fetch(`/debates/map/${debIndex}`);
        },
        onAvatarClick: (debId, commentId) => {
            // return api.removeComment(debId, commentId, "live").then(() => {
            //     dispatch(actions.deleteComment(debId, commentId));
            // });
        },
    };
};
