// @flow
import React, { Component } from "react";
import "./CanvasPreview.scss";
import contextTypes from "../contextTypes";
import LofCard from "../lof-card/LofCard";

type Props = {};

export class CanvasPreview<Props> extends Component {
    static defaultProps: Props = {};

    state = {};

    get root() {
        return {
            authorImageUrl:
                "https://firebasestorage.googleapis.com/v0/b/giro-2.appspot.com/o/images%2F4c852981.jpg?alt=media&token=e1c40a33-1c94-42f2-852e-100a7b0a0fd5",
            authorName: "אבי גבאי",
            cardImageRatio: 1.783,
            cardImageUrl:
                "https://firebasestorage.googleapis.com/v0/b/giro-2.appspot.com/o/images%2F8a352a6f.jpg?alt=media&token=221ce174-2a92-431d-8e78-ab6ce30fc571",
            cardTopic: "על נתניהו ומוסר",
            content:
                "זוהי שעה אפלה לדמוקרטיה. אחרי החסינות לנתניהו תגיע גם חסינות לטייקונים שתומכים בו ונתמכים על ידו.תרבות של חסינות היא תרבות של שחיתות. תרבות שתפגע ביכולת לחנך את ילדינו להבחין בין טוב לרע, בין חוקי ולא חוקי, בין מותר לאסור.אנחנו נהיה אופוזיציה לשלטון שיפגע בחוסנה ובאופייה הדמוקרטי של ישראל.",
            dateDebate: "2019-01-01 02:00:00",
            id: "i0",
            imageRatio: 1.7766497461928934,
            imageUrl: "",
            lofs: 1,
            originComments: "52",
            originLikes: "1.1k",
            originShares: "",
            participantId: "i1",
            personaId: "",
            videoId: "",
            videoUrl: "",
        };
    }

    render() {
        const { authorImageUrl } = this.root;

        return (
            <div className="CanvasPreview-container">
                <img src={authorImageUrl} />
            </div>
        );
    }
}

CanvasPreview.contextTypes = contextTypes;

export default CanvasPreview;
