import actions from "./sparkActions";
import middlewares from "./save";
import globals from "../utils/globals";

const api_middleware = store => next => async action => {
    let data;

    if (action.silent || (action.value && action.value.silent)) {
        return next(action);
    }

    switch (action.type) {
        case "GET":
            data = await globals.fetch(action.path, "GET", action.body); // prettier-ignore;
            break;

        case "POST":
            data = await globals.fetch(action.path, "POST", action.body); // prettier-ignore;
            break;

        case "DELETE":
            data = await globals.fetch(action.path, "DELETE", action.body); // prettier-ignore;
            break;
        default:
    }

    data = data || (await middlewares.appState(action, store));
    data = data || (await middlewares.bons(action, store));
    data = data || (await middlewares.contacts(action, store));
    data = data || (await middlewares.debates(action, store));
    data = data || (await middlewares.examples(action, store));
    data = data || (await middlewares.pendings(action, store));
    data = data || (await middlewares.dumps(action, store));
    data = data || (await middlewares.personas(action, store));
    data = data || (await middlewares.sessions(action, store));
    data = data || (await middlewares.tags(action, store));

    // do not run the action
    if (data && data.errorKey) {
        store.dispatch(
            actions.showToast(globals.i18n[data.errorKey], "warning"),
        );
        return data;
    }

    if (data && data.toast) {
        store.dispatch(actions.showToast(data.toast));
    }

    next(action);

    data = data || (await middlewares.builder(action, store));

    return data;
};

export default api_middleware;
