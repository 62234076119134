import { getString, setString, removeItem } from "./storage";
import globals from "./globals";

// https://debdb.com/44?fbclid=IwAR0vTCEwGI2FVKHikyjPfuCpPpmEMbQgPcGdgwz6b2z7kEaIevaWv8AJTPQ

const FLAG_SESSION_KEY = "debdb_analytics";
const FLAG_VIEW_KEY = "debdb_analytics_last_view";

const isDev = process.env.NODE_ENV === "development";
// const on = true;
const on = !isDev;

let sessionOn = false;

const log = message => {
    // console.log("message -> ", message);
};

const getDeviceInfo = () => ({
    userAgent: navigator.userAgent,
    width: window.innerWidth,
    height: window.innerHeight,
});

const init = async () => {
    log("init");

    const flag = getString(FLAG_SESSION_KEY);

    if (!on || flag || sessionOn) {
        return;
    }

    log("creating a session");
    sessionOn = true;

    const res = await globals.fetch("/sessions", "POST", {
        session: {
            ...getDeviceInfo(),
            search: document.location.search || "",
            referrer: document.referrer || "",
        },
    });

    const { session } = res || {},
        { id } = session || {};

    setString(FLAG_SESSION_KEY, id);
};

export const addView = async path => {
    if (!on) {
        return;
    }

    const flag = getString(FLAG_SESSION_KEY);

    if (!flag) {
        init();
        return;
    }

    log("adding a view");

    const res = await globals.fetch(`/sessions/${flag}/views`, "POST", {
        view: {
            path,
        },
    });

    const { view } = res || {},
        { id } = view || {};

    setString(FLAG_VIEW_KEY, id);
};

export const addError = async (stack, message) => {
    if (!on) {
        return;
    }

    const pathname = document.location.pathname;

    await globals.fetch("/errors", "POST", {
        error: {
            stack,
            pathname,
            message,
            deviceInfo: getDeviceInfo(),
        },
    });
};

window.addEventListener(
    "unload",
    () => {
        closeSessions();
    },
    false,
);

// window.onbeforeunload = function() {
//     closeSessions();
// };

export const closeSessions = () => {
    if (!on) return;

    log("closing session");
    sessionOn = false;

    const flag = getString(FLAG_SESSION_KEY);
    removeItem(FLAG_SESSION_KEY);
    removeItem(FLAG_VIEW_KEY);
    globals.api.sendBeacon(`/sessions/${flag}/finish`);
};

export const removeAnonymousSession = () => {
    if (!on) return;

    log("deleting U1 session");

    const flag = getString(FLAG_SESSION_KEY);
    closeSessions();
    globals.fetch(`/sessions/${flag}`, "DELETE");
};

window.addEventListener("error", function(event) {
    const { error } = event;
    addError(error.stack, error.message);
    return false;
});

window.addEventListener("unhandledrejection", function(event) {
    addError("", event.reason.message);
});

init();
