// @flow
import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import config from "../config";
import routes from "../config/client.routes";
import contextTypes from "../layout_modules/contextTypes";
import colors from "../constants/colors";
import "../utils/analytics";
import { isMobile } from "../utils/screen";
import { addError } from "../utils/analytics";
import components from "../config/client.components";
import ScreenLoader from "../layout_modules/screen-loader/ScreenLoader";

class App extends Component {
    static getDerivedStateFromError(e) {
        addError(e.stack, e.message);
    }

    getChildContext = () => {
        return {
            i18n: config.i18n,
            colors,
            showToast: this.props.showToast,
            isMobile: isMobile(),
        };
    };

    componentDidMount() {
        this.props.dispatch({ type: "START" });
    }

    componentDidCatch(error, info) {
        const { componentStack = "" } = info || {};
        addError("", componentStack);
    }

    render() {
        const { toast } = this.props;

        const UserBar = components.userBar;
        const Toast = components.toast;
        const SideMenu = components.sideMenu;

        return (
            <div className="App">
                <UserBar
                    onMenu={this.props.showSideMenu}
                    showLogin={config.product === "admin"}
                />

                <Suspense fallback={<ScreenLoader />}>
                    <Switch>
                        {Object.keys(routes).map(route => (
                            <Route
                                key={route}
                                exact
                                path={route}
                                component={routes[route].cmp}
                            />
                        ))}
                    </Switch>
                </Suspense>

                <Toast toast={toast} />
                <SideMenu onClose={this.props.hideSideMenu} />
            </div>
        );
    }
}

App.childContextTypes = contextTypes;

export default App;
