import server from "./server.prod";
import staging from "../server.staging";
import templates from "./templates";
import defaults from "./defaults";
import i18n from "./i18n";

export default {
    server,
    templates,
    defaults,
    i18n,
    staging,
};
