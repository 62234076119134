export const progress = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER_DEBATE_PROGRESS":
            return action.value;

        case "PATCH_BUILDER_DEBATE_PROGRESS":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const stats = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER_DEBATE_STATS":
            return action.value;

        case "PATCH_BUILDER_DEBATE_STATS":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const info = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER_DEBATE_INFO":
            return action.value;

        case "PATCH_BUILDER_DEBATE_INFO":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const root = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER_DEBATE_ROOT":
            return action.value;

        case "PATCH_BUILDER_DEBATE_ROOT":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const participant = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER_DEBATE_PARTICIPANT":
            return action.value;

        case "PATCH_BUILDER_DEBATE_PARTICIPANT":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const participants = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "SET_BUILDER_DEBATE_PARTICIPANTS":
            return action.value;

        case "SET_BUILDER_DEBATE_PARTICIPANT":
        case "PATCH_BUILDER_DEBATE_PARTICIPANT":
            return {
                ...state,
                [action.participantId]: participant(
                    state[action.participantId],
                    action,
                ),
            };

        case "DELETE_BUILDER_DEBATE_PARTICIPANT":
            newState = { ...state };
            delete newState[action.participantId];
            return newState;

        default:
            return state;
    }
};

export const comment = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER_DEBATE_COMMENT":
            return action.value;

        case "PATCH_BUILDER_DEBATE_COMMENT":
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const comments = (state = {}, action) => {
    let newState;

    switch (action.type) {
        case "ADD_BUILDER_DEBATE_COMMENT":
            return addComment(state, action);

        case "SET_BUILDER_DEBATE_COMMENTS":
            return action.value;

        case "SET_BUILDER_DEBATE_COMMENT":
        case "PATCH_BUILDER_DEBATE_COMMENT":
            return {
                ...state,
                [action.commentId]: comment(state[action.commentId], action),
            };

        case "DELETE_BUILDER_DEBATE_COMMENT":
            newState = { ...state };
            delete newState[action.commentId];
            return newState;

        default:
            return state;
    }
};

export const debate = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER_DEBATE":
            return action.value;

        case "PATCH_BUILDER_DEBATE":
            return {
                ...state,
                ...action.value,
            };

        case "SET_BUILDER_DEBATE_COMMENTS":
        case "SET_BUILDER_DEBATE_COMMENT":
        case "PATCH_BUILDER_DEBATE_COMMENT":
        case "DELETE_BUILDER_DEBATE_COMMENT":
        case "ADD_BUILDER_DEBATE_COMMENT":
            return {
                ...state,
                comments: comments(state.comments, action),
            };
        case "SET_BUILDER_DEBATE_PARTICIPANTS":
        case "SET_BUILDER_DEBATE_PARTICIPANT":
        case "PATCH_BUILDER_DEBATE_PARTICIPANT":
        case "DELETE_BUILDER_DEBATE_PARTICIPANT":
            return {
                ...state,
                participants: participants(state.participants, action),
            };
        case "SET_BUILDER_DEBATE_ROOT":
        case "PATCH_BUILDER_DEBATE_ROOT":
            return {
                ...state,
                root: root(state.root, action),
            };

        case "SET_BUILDER_DEBATE_INFO":
        case "PATCH_BUILDER_DEBATE_INFO":
            return {
                ...state,
                info: info(state.info, action),
            };
        case "SET_BUILDER_DEBATE_STATS":
        case "PATCH_BUILDER_DEBATE_STATS":
            return {
                ...state,
                stats: stats(state.stats, action),
            };
        case "SET_BUILDER_DEBATE_PROGRESS":
        case "PATCH_BUILDER_DEBATE_PROGRESS":
            return {
                ...state,
                progress: progress(state.progress, action),
            };

        case "NUDGE_BUILDER_COMMENT_PARTICIPANT":
            return nudgeCommentParticipant(state, action);

        default:
            return state;
    }
};

export const builder = (state = {}, action) => {
    switch (action.type) {
        case "SET_BUILDER":
            return action.value;

        case "PATCH_BUILDER":
            return {
                ...state,
                ...action.value,
            };

        case "ADD_BUILDER_DEBATE_COMMENT":
        case "SET_BUILDER_DEBATE_PROGRESS":
        case "PATCH_BUILDER_DEBATE_PROGRESS":
        case "SET_BUILDER_DEBATE_STATS":
        case "PATCH_BUILDER_DEBATE_STATS":
        case "SET_BUILDER_DEBATE_INFO":
        case "PATCH_BUILDER_DEBATE_INFO":
        case "SET_BUILDER_DEBATE_ROOT":
        case "PATCH_BUILDER_DEBATE_ROOT":
        case "SET_BUILDER_DEBATE_PARTICIPANTS":
        case "SET_BUILDER_DEBATE_PARTICIPANT":
        case "PATCH_BUILDER_DEBATE_PARTICIPANT":
        case "DELETE_BUILDER_DEBATE_PARTICIPANT":
        case "SET_BUILDER_DEBATE_COMMENTS":
        case "SET_BUILDER_DEBATE_COMMENT":
        case "PATCH_BUILDER_DEBATE_COMMENT":
        case "DELETE_BUILDER_DEBATE_COMMENT":
        case "SET_BUILDER_DEBATE":
        case "PATCH_BUILDER_DEBATE":
        case "NUDGE_BUILDER_COMMENT_PARTICIPANT":
            return {
                ...state,
                debate: parseDebate(debate(state.debate, action)),
            };

        default:
            return state;
    }
};

const nudgeCommentParticipant = (state, action) => {
    const { commentId } = action;

    let { participants, comments, root } = state,
        comment = comments[commentId] || {};

    if (commentId === "i0") {
        comment = root;
    }

    let { participantId = "" } = comment;

    participantId = parseInt(participantId.replace("i", ""), 10);
    if (++participantId >= Object.values(participants).length) {
        participantId = 1;
    }
    participantId = "i" + participantId;

    const participant = participants[participantId],
        { imageUrl, title } = participant || {};

    comment.participantId = participantId;
    if (commentId === "i0") {
        comment.authorImageUrl = imageUrl;
        comment.authorName = title;
    }

    return state;
};

const parseDebate = state => {
    let { comments, info } = state || {},
        { media, size } = info || {};

    if (media === "video") {
        size = "full";
    } else {
        const len = Object.values(comments || {}).length;
        size = len === 1 ? "single" : "full";
    }

    if (state && state.info) {
        state.info.size = size;
    }

    return state;
};

const addComment = (state, action) => {
    const ids = Object.values(state || {}).map(comment => {
        const { id = "" } = comment;
        return parseInt(id.replace("i", ""), 10);
    });

    const max = ids.reduce((output, i) => Math.max(output, i), 0);
    const nexId = "i" + (max + 1);

    return {
        ...state,
        [nexId]: {
            ...action.value,
            id: nexId,
        },
    };
};

/*

 get persona() {
        const { personas } = this.props;
        const { root } = this.debate,
            { personaId } = root;

        if (personaId) {
            return Object.values(personas).filter(p => p.id === personaId)[0];
        }

        return null;
    }

    refresh = () => {
        const { comments, stats, participants } = this.state;
        let { media, size, cardTopic } = stats || {};

        if (media === "video") {
            size = "full";
        } else {
            const len = comments.length;
            size = len === 1 ? "single" : "full";
        }

        if (this.persona) {
            const participant = { ...this.persona, id: "i1" };
            participants["i1"] = participant;
            this.setState({ participants });
            comments[0].participant = participant;
            comments[0].participantId = "i1";
        }

        this.onChangeStats({ size, title: cardTopic }, true);
    };


     addDebate = async () => {
        const { comments, participants, stats } = this.state;

        this.setState({ isSaving: true });

        const id = await this.props.addDebate(comments, participants, stats);

        this.setState({ isSaving: false });
        this.onClear();

        this.props.history.push(`/${id}`);
    };

*/
