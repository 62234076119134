import screens from "./client.screens";
import About from "../layout_modules/screen-home/ScreenHomeAbout";
import Volunteer from "../layout_modules/screen-home/ScreenHomeVolunteer";
import Roadmap from "../layout_modules/roadmap/Roadmap";
import Egg from "../layout_modules/egg/Egg";
import CanvasPreview from "../layout_modules/canvas-preview/CanvasPreview";

export default {
    "/course": { cmp: screens.course },
    "/about": { cmp: About },
    "/roadmap": { cmp: Roadmap },
    "/logout": { cmp: screens.logout },
    "/volunteers": { cmp: Volunteer },
    "/contact": { cmp: screens.contact },
    "/egg": { cmp: Egg },
    "/wiki": { cmp: screens.wikiHome },
    "/admin": { cmp: screens.admin },
    "/admin/*": { cmp: screens.admin },
    "/play": { cmp: CanvasPreview },
    "/:debIndex": { cmp: screens.root },
    "/wiki/:tagIndex": { cmp: screens.wiki },
    "/": { cmp: screens.browse },
};
