import * as filters from "./filters";

export const sizes = {
    single: {
        id: "single",
        titleKey: "optionSizeSingle",
        icon: "keyboard_arrow_up",
    },
    full: {
        id: "full",
        titleKey: "optionSizeFull",
        icon: "keyboard_capslock",
    },
};

export const media = {
    text: {
        id: "text",
        icon: "short_text",
        titleKey: "optionMediaText",
    },
    image: {
        id: "image",
        icon: "far fa-image",
        titleKey: "optionMediaImage",
    },
    video: {
        id: "video",
        icon: "play_circle_outline",
        titleKey: "optionMediaVideo",
    },
};

export const layers = {
    lofs: {
        id: "lofs",
        titleKey: "optionLayerLofs",
    },
    nvcs: {
        id: "nvcs",
        titleKey: "optionLayerNvcs",
    },
    subtext: {
        id: "subtext",
        titleKey: "optionLayerSubtext",
    },
    factCheck: {
        id: "factCheck",
        titleKey: "optionLayerFactCheck",
    },
};

export const flavours = {
    tagging: {
        id: "tagging",
        titleKey: "optionFlavourTagging",
    },
    rephrasing: {
        id: "rephrasing",
        titleKey: "optionFlavourRephrasing",
    },
};

export const sources = {
    facebook: {
        id: "facebook",
        titleKey: "optionSourcesFacebook",
    },
    twitter: {
        id: "twitter",
        titleKey: "optionSourcesTwitter",
    },
    youtube: {
        id: "youtube",
        titleKey: "optionSourcesYoutube",
    },
};

export const debateEditViews = {
    1: {
        id: 1,
        icon: "list",
        titleKey: "list",
    },
    2: {
        id: 2,
        icon: "people",
        titleKey: "participants",
    },
};

export const debateCommentViews = {
    1: {
        id: 1,
        icon: "fab fa-searchengin",
        titleKey: "logicalFallacies",
    },
    2: {
        id: 2,
        icon: "pageview",
        titleKey: "arguments",
    },
};

export const addDebateSteps = {
    1: {
        id: 1,
        titleKey: "logicalFallacies",
    },
    2: {
        id: 2,
        icon: "pageview",
        titleKey: "arguments",
    },
};

export const viewsAdminDebates = {
    waitingForTagging: {
        id: "waitingForTagging",
        titleKey: "optionViewDebatesPreTag",
        filter: filters.debatesWaitingForTagging,
    },
    waitingForPublish: {
        id: "waitingForPublish",
        titleKey: "optionViewDebatesPrePublish",
        filter: filters.debatesWaitingForPublish,
    },
    published: {
        id: "published",
        titleKey: "optionViewDebatesPublished",
        filter: filters.debatesPublished,
    },
    deleted: {
        id: "deleted",
        titleKey: "optionViewDebatesPreRemove",
        filter: filters.debatesDeleted,
    },
    all: {
        id: "all",
        titleKey: "optionViewDebatesAll",
        filter: filters.all,
    },
};

export const viewsAdminCCStudio = {
    text: {
        id: "text",
        icon: "short_text",
        titleKey: "optionViewCCText",
    },
    timing: {
        id: "timing",
        icon: "timer",
        titleKey: "optionViewCCTiming",
    },
    done: {
        id: "done",
        icon: "check_box",
        titleKey: "optionViewCCTDone",
    },
};

export const keysCCStudioEdit = {
    fast: {
        id: "fast",
        icon: state => "timer",
        disabled: state => false,
        titleKey: "",
        hotkey: "f5",
        hotkeyCode: "F5",
    },
    slow: {
        id: "slow",
        icon: state => "timelapse",
        disabled: state => false,
        titleKey: "",
        hotkey: "f4",
        hotkeyCode: "F4",
    },
    forward5: {
        id: "forward5",
        icon: state => "forward_5",
        disabled: state => false,
        titleKey: "",
        hotkey: "f3",
        hotkeyCode: "F3",
    },
    play: {
        id: "play",
        icon: state => (state.playing ? "pause" : "play_arrow"),
        disabled: state => false,
        big: true,
        titleKey: "",
        hotkey: "f2",
        hotkeyCode: "F2",
    },
    replay5: {
        id: "replay5",
        icon: state => "replay_5",
        disabled: state => false,
        titleKey: "",
        hotkey: "f1",
        hotkeyCode: "F1",
    },
};

export const keysCCStudio = {
    forward5: {
        id: "forward5",
        icon: state => "forward_5",
        disabled: state => false,
        titleKey: "buttonCCForward5",
        hotkey: "f3",
        hotkeyCode: "F3",
    },
    play: {
        id: "play",
        icon: state => (state.playing ? "pause" : "play_arrow"),
        disabled: state => false,
        big: false,
        titleKey: "buttonCCPlay",
        hotkey: "f2",
        hotkeyCode: "F2",
    },
    replay5: {
        id: "replay5",
        icon: state => "replay_5",
        disabled: state => false,
        titleKey: "buttonCCBack5",
        hotkey: "f1",
        hotkeyCode: "F1",
    },
};
