export default {
    id: "debdb",
    title: "אודות הפרוייקט",
    color: "#fff",
    description: `פרוייקט debdb נועד לתקן את הכשלים של תרבות הדיון באינטרנט ולחנך דור חדש של שליחים של דיון מקרב וענייני.
    
    debdb מקטלג דיונים ומוסיף מטא-רבדים של כשלים לוגיים ותקשורת מקרבת. הדיונים עוברים תהליך של ערפול, בו מוחלפים השמות והתמונות של הכותבים על מנת לשמור על פרטיותם. פוסטים ציבוריים של פוליטיקאים ואנשי ציבור נשארים עם פרטי הכותב המקוריים.
    
    דיונים באינטרנט, בין אם ציבוריים או פרטיים, מתנהלים לעיתים כשיח חירשים. הצדדים בדיון יוצאים טעונים רגשית ומרגישים שהצד השני העלה טיעונים פגומים ולקה בחשיבה כושלת. רק לעיתים אנחנו עושים התבוננות ביקורתית בכשלים שלנו או מחפשים להטמיע דברים חדשים שלמדנו מהצד השני. התוצר הוא סביבת דיון ״רעילה״ במובן שהיחשפות אליה באופן אקטיבי או פאסיבי מרחיקה אותנו מהאמת שהיא באה לחקור ומשאירה טעם מר של קיטוב.
    
    הסיבה העיקרית אינה נעוצה במדיום, או באופי המתדיינים עצמם, אלא בכישורי הדיון של הצדדים. כאשר אנשים אינם מיומנים לזהות כשלים לוגיים, האופן בו הם מתגוננים מפניהם נוטה להיות ע״י הצגת כשלים לוגיים  משלהם. מעגל הקסמים שנוצר יכול בקלות לדרדר את הדיון לאריסטיקה - ״מלחמת בוץ מילולית״ שנשכחת במורד הפיד של כולנו.
    
    פרוייקט debdb סורק ועושה אנליזה לדיון ע״י צוות מקצועי, מיומן ונטול שיוך ובכך מאפשר למשתתפים, להרהר, ללמוד ולהבין טוב יותר את מהלך הדיון. בכך התהליך דומה ל״הילוך חוזר״ במשחקי ספורט, שם   האטת המהירות מנגישה מימדים נוספים של ניתוח מהלכים.`,
    startingDate: "2019-05-01",
    status: "running",
    duration: "חודש",
    languages: "רק עברית",
    webLink: "https://debdb.com",
    facebookLink: "",
    medium: "אתר",
    nextGoal: "קהילה של 5 אנשים",
    numberOfParticipants: 2,
    hours: 200,
};
