import * as appState from "./actions/appState";
import * as builder from "./actions/builder";
import * as bons from "./actions/bons";
import * as contacts from "./actions/contacts";
import * as debates from "./actions/debates";
import * as examples from "./actions/examples";
import * as modal from "./actions/modal";
import * as sessions from "./actions/sessions";
import * as personas from "./actions/personas";
import * as pendings from "./actions/pendings";
import * as dumps from "./actions/dumps";
import * as tags from "./actions/tags";

const showToast = (text, type = "success") =>
    appState.patchAppState({ toast: { type, text } });

export default {
    showToast,
    ...appState,
    ...bons,
    ...builder,
    ...contacts,
    ...debates,
    ...examples,
    ...modal,
    ...sessions,
    ...personas,
    ...pendings,
    ...dumps,
    ...tags,
};
