// @flow
import React, { Component } from "react";
import "./VideoPlayer.scss";
import contextTypes from "../contextTypes";
import YoutubePlayer from "react-player/lib/players/YouTube";
import FacebookPlayer from "react-player/lib/players/Facebook";
import VideoControls from "./VideoControls";
import VideoTime from "./VideoTime";
import VideoSpeed from "./VideoSpeed";

/* valid urls 
https://www.youtube.com/watch?v=oUFJJNQGwhk
https://www.youtube.com/playlist?list=PLDEcUiPhzbjI217qs5KgMvbvx6-fgY_Al
https://soundcloud.com/miami-nights-1984/accelerated
https://www.facebook.com/facebook/videos/10153231379946729/
https://vimeo.com/90509568
https://www.twitch.tv/videos/106400740
https://www.twitch.tv/kronovi
https://streamable.com/moo
https://home.wistia.com/medias/e4a27b971d
https://www.dailymotion.com/video/x5e9eog
https://www.mixcloud.com/mixcloud/meet-the-curators/
*/

const noop = () => {};

export class VideoPlayer extends Component {
    static defaultProps = {
        onProgress: noop,
        onPlay: noop,
        onPause: noop,
    };

    state = {
        playing: false,
        playbackRate: 1,
        playedSeconds: 0,
    };

    constructor() {
        super();
        this.video = React.createRef();
    }

    componentWillReceiveProps(props) {
        const { playing } = props;

        if (typeof playing !== "undefined" && playing !== this.state.playing) {
            this.setState({ playing });
        }
    }

    onProgress = durations => {
        const { playedSeconds } = durations;
        this.setState({ playedSeconds });
        this.props.onProgress({ playedSeconds });
    };

    get config() {
        return {
            facebook: {
                appId: "603062113546231",
                fullscreen: false,
                allowfullscreen: false,
                autoplay: true,
                showCaptions: true,
            },
            youtube: {
                playerVars: {
                    playerVars: {
                        rel: 0,
                        fs: 0,
                    },
                },
            },
        };
    }

    onPause = () => {
        this.setState({ playing: false });
        this.props.onPause();
    };

    onPlay = () => {
        this.setState({ playing: true });
        this.props.onPlay();
    };

    onReady = () => {};

    renderVideo() {
        const { url, height = "auto", width = "100%" } = this.props;
        const { playing, playbackRate } = this.state;

        const Cmp =
            url.indexOf("youtube.com") >= 0 ? YoutubePlayer : FacebookPlayer;

        return (
            <Cmp
                ref={this.video}
                playing={playing}
                onReady={this.onReady}
                controls
                width={width}
                height={height}
                onProgress={this.onProgress}
                playbackRate={playbackRate}
                onPlay={this.onPlay}
                onPause={this.onPause}
                light={true}
                url={url}
                progressInterval={400}
                config={this.config}
            />
        );
    }

    seek = delta => {
        let { playedSeconds } = this.state;

        playedSeconds += delta;

        if (this.video.current) {
            this.video.current.seekTo(playedSeconds, "seconds");
        }

        this.delta = 0;

        this.setState({ playedSeconds });
    };

    seekSum = delta => {
        this.delta += delta;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.seek, 1000);
    };

    toggleSpeed = () => {
        let { playbackRate } = this.state;
        const speeds = [0.5, 0.75, 1, 1.25, 1.5];

        let index = speeds.indexOf(playbackRate);
        index = (index + 1) % speeds.length;

        playbackRate = speeds[index];

        this.setState({ playbackRate });
    };

    mark = () => {
        const currentTime = this.video.current.getCurrentTime();
        this.props.onMark(currentTime);
    };

    onClick = id => {
        const { rewindOnPause = 0 } = this.props;
        let { playing } = this.state;

        switch (id) {
            case "fast":
                this.mark();
                break;
            case "forward5":
                this.seek(5);
                break;
            case "play":
                playing = !playing;

                // go 0.5 seconds back when paused
                if (!playing && rewindOnPause) {
                    this.seek(-rewindOnPause);
                }

                break;
            case "replay5":
                this.seek(-5);
                break;
            case "slow":
                this.toggleSpeed();
                break;
            default:
        }

        this.setState({ playing });
    };

    renderControls() {
        const { controls } = this.props;
        const { playedSeconds, playbackRate } = this.state;

        if (!controls) return null;

        if (controls === "simple") {
            return (
                <VideoControls
                    videoState={this.state}
                    onClick={this.onClick}
                    controls={controls}
                />
            );
        }

        return (
            <div>
                <VideoSpeed playbackRate={playbackRate} />
                <VideoTime playedSeconds={playedSeconds} />
                <VideoControls videoState={this.state} onClick={this.onClick} />
            </div>
        );
    }

    render() {
        const { url } = this.props;

        if (!url) return null;

        return (
            <div className="VideoPlayer-container" style={this.style}>
                {this.renderVideo()}
                {this.renderControls()}
            </div>
        );
    }
}

VideoPlayer.contextTypes = contextTypes;

export default VideoPlayer;
