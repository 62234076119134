import dayjs from "dayjs";
import "dayjs/locale/he";
import relativeTime from "dayjs/plugin/relativeTime";
import unix from "./unix";
import future from "./future";
import timestamp from "./timestamp";
import duration from "./duration";

dayjs.locale("he");
dayjs.extend(relativeTime);
dayjs.extend(timestamp);
dayjs.extend(unix);
dayjs.extend(future);
dayjs.extend(duration);
