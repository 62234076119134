import actions from "../sparkActions";

export default dispatch => {
    return {
        addBon: (debId, bon) => {
            return dispatch(actions.addBon(debId, bon.id, bon));
        },
        deleteBon: (debId, bonId) => {
            return dispatch(
                actions.patchBon(debId, bonId, { isDeleted: true }),
            );
        },
        vote: (debId, bonId, bon) => {
            return dispatch(actions.patchBon(debId, bonId, bon));
        },
        editBon: (debId, bonId) => {
            dispatch(actions.patchCurrentId({ bonId }));
            dispatch(
                actions.setModal({
                    id: "editBon",
                    props: {},
                }),
            );
        },
        patchBon: (debId, bonId, value) => {
            return dispatch(actions.patchBon(debId, bonId, value));
        },
    };
};
