import actions from "../sparkActions";

export default dispatch => {
    return {
        getDumps: () => {
            return dispatch(actions.getDumps());
        },
        patchDump: (dumpId, dump) => {
            return dispatch(actions.patchDump(dumpId, dump));
        },
        deleteDumps: () => {
            // deletes only the deleted dumps
            return dispatch(actions.deleteDumps());
        },
    };
};
