import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { guid8 } from "./guid";
import { imageFromUrl } from "./image";
import config from "../config";

let storageRef;

export const init = () => {
    firebase.initializeApp(config.server.FIREBASE_CONFIG);
    storageRef = firebase.storage().ref();
};

init();

export const signInWithFacebook = () => {
    // firebase.auth().languageCode = "fr_FR";
    const provider = new firebase.auth.FacebookAuthProvider();

    return new Promise((resolve, reject) => {
        provider.setCustomParameters({
            display: "popup",
        });

        firebase
            .auth()
            .signInWithPopup(provider)
            .then(function(result) {
                var user = result.user;
                resolve(user);
            })
            .catch(function(error) {
                reject(error.message);
            });
    });
};

export const signInWithEmailAndPassword = (email, password) => {
    // firebase.auth().languageCode = "fr_FR";

    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(function() {
                const user = currentUser();
                resolve(user);
            })
            .catch(function(error) {
                reject(error.message);
            });
    });
};

export const onAuthChange = callback => {
    firebase.auth().onAuthStateChanged(callback);
};

export const currentUser = () => {
    return firebase.auth().currentUser;
};

export const getToken = forceRefresh => {
    return firebase.auth().currentUser.getIdToken(forceRefresh);
};

export const signOut = () => {
    firebase.auth().signOut();
};

export const uploadFile = (file, progressCallback) => {
    return new Promise((resolve, reject) => {
        const remotePath = `images/${guid8()}.jpg`;

        var uploadTask = storageRef.child(remotePath).put(file);

        uploadTask.on(
            "state_changed",
            snapshot => {
                if (!progressCallback) return;

                const percent =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                progressCallback({ percent });

                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        progressCallback({ status: "PAUSED" });
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        progressCallback({ status: "RUNNING" });
                        break;
                    default:
                }
            },
            error => {
                reject(error);
            },
            () => {
                uploadTask.snapshot.ref
                    .getDownloadURL()
                    .then(function(downloadURL) {
                        resolve(downloadURL);
                    });
            },
        );
    });
};

export const getDebateUrl = (id, isLive) => {
    const category = isLive ? "live" : "pending";

    return `${config.server.consoleURL}/debates/${category}/${id}`;
};

export const uploadFileFromUrl = async (url, progressCallback) => {
    const blob = await imageFromUrl(url);
    return uploadFile(blob, progressCallback);
};
