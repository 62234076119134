// @flow
import React, { Component } from "react";
import "./VideoPost.scss";
import contextTypes from "../contextTypes";
import VideoPlayer from "../video-player/VideoPlayer";
import Sentences from "../sentences-video/SentencesVideo";
import PostHeader from "../post-header/PostHeader";
import PanelVideo from "../panel-video/PanelVideo";
import classnames from "classnames";
import Icon from "../icons/Icon";

const noop = () => {};

export class VideoPost extends Component {
    state = {
        currentIndex: 0,
        isPlaying: false,
        neverPlayed: true,
    };

    get debate() {
        const { debate } = this.props;

        return debate || {};
    }

    get root() {
        const { root } = this.debate;
        return root || {};
    }

    get comments() {
        const { comments } = this.debate;
        return comments;
    }

    get content() {
        return this.comments
            .map(comment => comment.content.trim() || "")
            .join("");
    }

    get isLTR() {
        const match = this.content.substr(0, 1).match(/[a-zA-Z]/);
        return match && match.length > 0;
    }

    get currentIndex() {
        const { playedSeconds } = this.state;

        let index = 0;

        for (let comment of this.comments) {
            const { timeComment } = comment;

            if (timeComment > playedSeconds + 0.3) {
                break;
            }

            index++;
        }

        return index - 1;
    }

    get highlights() {
        const { videoHighlights } = this.debate;
        return videoHighlights;
    }

    get isYoutube() {
        const { videoUrl } = this.root || {};

        if (!videoUrl) {
            return false;
        }

        return videoUrl.indexOf("youtube.com") >= 0;
    }

    setFirstComment = () => {
        setTimeout(() => {
            this.props.changeCommentId("i0");
        });
    };

    componentDidMount() {
        this.setFirstComment();
    }

    onProgress = data => {
        this.setState(data);
    };

    renderHeader() {
        const { isMobile } = this.context;
        const { neverPlayed } = this.state;

        if (!neverPlayed && isMobile) return null;

        return (
            <div className="header">
                <PostHeader
                    comment={this.root}
                    onAvatarClick={noop}
                    onDateClick={noop}
                />
            </div>
        );
    }

    togglePlaying = () => {
        let { isPlaying } = this.state;
        isPlaying = !isPlaying;
        this.setState({ isPlaying });
    };

    renderRoot() {
        const { isMobile } = this.context;
        const { videoUrl } = this.root;
        const { isPlaying } = this.state;

        return (
            <div className="root">
                <VideoPlayer
                    onPlay={() =>
                        this.setState({ isPlaying: true, neverPlayed: false })
                    }
                    onPause={() => this.setState({ isPlaying: false })}
                    playing={isPlaying}
                    url={videoUrl}
                    height={isMobile ? 210 : 270}
                    onProgress={this.onProgress}
                />
            </div>
        );
    }

    renderCC() {
        const { editMode } = this.props;

        const className = this.isLTR ? "ltr" : "rtl";

        return (
            <div className="cc">
                <Sentences
                    className={className}
                    comments={this.comments}
                    highlights={this.highlights}
                    currentIndex={this.currentIndex}
                    editMode={editMode}
                    selectedSentences={this.props.selectedSentences}
                    onSentenceClick={this.props.onSentenceClick}
                    changeSentence={this.props.changeSentence}
                />
            </div>
        );
    }

    renderPanel() {
        const { neverPlayed } = this.state;

        if (neverPlayed) return null;

        return (
            <div className="lofs">
                <PanelVideo
                    debate={this.debate}
                    currentIndex={this.currentIndex}
                />
            </div>
        );
    }

    renderPause() {
        const { isPlaying, neverPlayed } = this.state;

        let icon = isPlaying ? "pause" : "play_arrow";

        if (neverPlayed) {
            icon = "";
        }

        return (
            <div className="play-pause" onClick={this.togglePlaying}>
                <Icon name={icon} size={40} color="white" />
            </div>
        );
    }

    renderMessage() {
        const { i18n, isMobile } = this.context;
        const { neverPlayed } = this.state;

        if (!neverPlayed || this.isYoutube || !isMobile) return null;

        return <div className="lofs message">{i18n.videoInstructions}</div>;
    }

    render() {
        const { minimal } = this.props;

        const className = classnames("VideoPost-container", {
            minimal,
        });

        return (
            <article className={className}>
                {this.renderHeader()}
                {this.renderRoot()}
                {this.renderCC()}
                {this.renderMessage()}
                {this.renderPanel()}
                {this.renderPause()}
            </article>
        );
    }
}

VideoPost.contextTypes = contextTypes;

export default VideoPost;
