// @flow
import React, { Component } from "react";
import "./LofCard.scss";
import contextTypes from "../contextTypes";
import dayjs from "dayjs";
import Icon from "../icons/Icon";
import { layers } from "../../constants/options";
import RemoteImage from "../remote-image/RemoteImage";

const noop = () => {};

export class LofCard extends Component {
    static defaultProps = {
        onClick: noop,
    };

    get debate() {
        const { debate } = this.props;

        return debate || {};
    }

    get info() {
        const { info } = this.debate;

        return info || {};
    }

    get root() {
        const { root } = this.debate;
        return root || {};
    }

    get participant() {
        const { participants } = this.debate;
        const { participantId } = this.root;
        const participant = participants[participantId];

        return participant || {};
    }

    state = {};

    onClick = () => {
        const { index } = this.debate;
        this.props.onClick(index);
    };

    renderPreview() {
        const { media } = this.info;
        const { content } = this.root;

        if (media === "video") {
            return <br />;
        }

        return (
            <div className="preview">
                <span>&ldquo;</span>
                {content}
            </div>
        );
    }

    renderTop() {
        const { i18n } = this.context;
        const { layer } = this.info;

        const item = layers[layer],
            { titleKey } = item || {},
            title = i18n[titleKey];

        const className = `header color-${layer}`;

        return <div className={className}>{title}</div>;
    }

    renderLofs() {
        const { layer } = this.info;
        const { lofs } = this.root;

        const className = `count top color-${layer}`;

        return (
            <div className={className}>
                <Icon name="fab fa-searchengin" />
                <span>{lofs}</span>
            </div>
        );
    }

    renderPlay() {
        const { media } = this.info;

        if (media !== "video") {
            return null;
        }

        return (
            <div className="play">
                <Icon name="play_arrow" color="#333" size={40} />
            </div>
        );
    }

    render() {
        const {
                dateDebate,
                originLikes,
                cardImageUrl,
                cardTopic,
                cardImageRatio,
            } = this.root,
            { imageUrl, title } = this.participant;

        const date = dayjs(dateDebate).fromNow();

        return (
            <article className="LofCard-container" onClick={this.onClick}>
                {this.renderTop()}
                <div className="inner">
                    {this.renderLofs()}
                    <div className="row">
                        <div
                            className="image"
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                            }}
                        />
                        <div>
                            <div className="name">{title}</div>
                            <div className="date">{date}</div>
                        </div>
                    </div>
                    <div className="topic">{cardTopic}</div>
                    {this.renderPreview()}
                    <div className="image">
                        <RemoteImage
                            className="thumb"
                            src={cardImageUrl}
                            ratio={cardImageRatio}
                            alt="card"
                        />
                        {this.renderPlay()}
                    </div>
                    <div className="stats">
                        <div className="stat small">
                            <Icon name="thumb_up" size={20} />
                            <span>{originLikes}</span>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

LofCard.contextTypes = contextTypes;

export default LofCard;
