import config from "./index";
import api, { _fetch } from "../utils/fetch";
import * as firebase from "../utils/firebase";
import globals from "../utils/globals";

globals.config = config;
globals.i18n = config.i18n;
globals.fetch = _fetch;
globals.api = api;
globals.firebase = firebase;
