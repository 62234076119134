import { createSelector } from "reselect";
import * as raw from "./raw";
import * as appState from "./appState";
import * as bons from "./bons";
import * as tags from "./tags";
import * as examples from "./examples";
import * as personas from "./personas";
import * as inputs from "../../constants/inputs";
import actions from "../sparkActions";
import globals from "../../utils/globals";

export const modal = raw.modalRaw;

export const modalBon = createSelector(
    bons.bon,
    tags.tags,
    appState.debateId,
    appState.bonId,
    (_bon, _tags, debateId, bonId) => ({
        isUpdate: true,
        data: _bon,
        inputs: [inputs.tagId, inputs.explanation],
        extraOptions: {
            tags: Object.values(_tags),
        },
        saveTitleKey: "bonSave",
        okSavedKey: "bonSavedOk",
        okDeletedKey: "bonDeletedOk",
        params: [debateId, bonId],
        saveAction: (params, data) => {
            const arr = Object.values(params);
            return actions.patchBon.apply(this, [...arr, data]);
        },
        deleteAction: params => {
            const arr = Object.values(params);
            return actions.patchBon.apply(this, [...arr, { isDeleted: true }]);
        },
    }),
);

export const modalTag = createSelector(
    appState.overlay,
    _overlay => ({
        data: globals.defaults.defaultTag,
        inputs: [inputs.title, inputs.description],
        saveTitleKey: "tagSave",
        okSavedKey: "tagAddOk",
        params: [_overlay, 0],
        saveAction: (params, data) => {
            const arr = Object.values(params);
            return actions.addTag.apply(this, [...arr, data]);
        },
    }),
);

export const modalExample = createSelector(
    appState.overlay,
    tags.tags,
    (_overlay, _tags) => ({
        data: globals.defaults.defaultExample,
        inputs: [inputs.tagId, inputs.description],
        extraOptions: {
            tags: Object.values(_tags),
        },
        saveTitleKey: "exampleSave",
        okSavedKey: "exampleAddOk",
        params: [_overlay, 0],
        saveAction: (params, data) => {
            const arr = Object.values(params);
            return actions.addExample.apply(this, [...arr, data]);
        },
    }),
);

export const modalPersona = createSelector(
    appState.overlay,
    _overlay => ({
        data: globals.defaults.defaultPersona,
        inputs: [
            inputs.personaName,
            inputs.personaImage,
            inputs.personaFacebook,
            inputs.personaTwitter,
            inputs.personaInstagram,
            inputs.personaWiki,
        ],
        saveTitleKey: "savePersona",
        okSavedKey: "personaAddOk",
        params: [0],
        width: 300,
        saveAction: (params, data) => {
            const arr = Object.values(params);
            return actions.addPersona.apply(this, [...arr, data]);
        },
    }),
);

// ******************************************** */

export const ModalAddTag = createSelector(
    modalTag,
    _modalTag => ({
        ..._modalTag,
        width: 520,
    }),
);

export const ModalAddPersona = createSelector(
    modalPersona,
    _modalPersona => ({
        ..._modalPersona,
        width: 370,
    }),
);

export const ModalAddExample = createSelector(
    modalExample,
    _modalExample => ({
        ..._modalExample,
        width: 520,
    }),
);

export const ModalEditBon = createSelector(
    modalBon,
    _modalBon => ({
        ..._modalBon,
        width: 400,
    }),
);

// ******************************************** */

export const ModalEditPersona = createSelector(
    modalPersona,
    personas.persona,
    appState.personaId,
    (_modalPersona, _persona, _personaId) => {
        return {
            ..._modalPersona,
            okSavedKey: "personaSaveOk",
            okDeletedKey: "personaDeletedOk",
            isUpdate: true,
            data: _persona,
            params: [_personaId],
            width: 370,
            saveAction: (params, data) => {
                const arr = Object.values(params);
                return actions.patchPersona.apply(this, [...arr, data]);
            },
            deleteAction: params => {
                const arr = Object.values(params);
                return actions.patchPersona.apply(this, [
                    ...arr,
                    { isDeleted: true },
                ]);
            },
        };
    },
);

export const ModalEditTag = createSelector(
    modalTag,
    tags.tag,
    appState.tagId,
    appState.overlay,
    (_modalTag, _tag, _tagId, _layerId) => {
        return {
            ..._modalTag,
            okSavedKey: "tagSaveOk",
            okDeletedKey: "tagDeletedOk",
            isUpdate: true,
            data: _tag,
            params: [_layerId, _tagId],
            width: 550,
            saveAction: (params, data) => {
                const arr = Object.values(params);
                return actions.patchTag.apply(this, [...arr, data]);
            },
            deleteAction: params => {
                const arr = Object.values(params);
                return actions.patchTag.apply(this, [
                    ...arr,
                    { isDeleted: true },
                ]);
            },
        };
    },
);

export const ModalEditExample = createSelector(
    modalExample,
    examples.example,
    appState.exampleId,
    appState.overlay,
    (_modalExample, _example, _exampleId, _layerId) => {
        return {
            ..._modalExample,
            okSavedKey: "exampleSaveOk",
            okDeletedKey: "exampleDeletedOk",
            isUpdate: true,
            data: _example,
            params: [_layerId, _exampleId],
            width: 550,
            saveAction: (params, data) => {
                const arr = Object.values(params);
                return actions.patchExample.apply(this, [...arr, data]);
            },
            deleteAction: params => {
                const arr = Object.values(params);
                return actions.patchExample.apply(this, [
                    ...arr,
                    { isDeleted: true },
                ]);
            },
        };
    },
);

/*
    title: "",
    facebookUrl: "",
    imageUrl: "",
    instagramUrl: "",
    wikiUrl: "",
    twitterUrl: "",
    */
