// @flow
import React from "react";
import "./ScreenHomeVolunteer.scss";
import contextTypes from "../contextTypes";
import WebBar from "../web-bar/WebBar";
import { Link } from "react-router-dom";
import useToTop from "../../hooks/useToTop";
import globals from "../../utils/globals";

function ScreenHomeVolunteer(props, context) {
    const { i18n } = context;

    useToTop();

    return (
        <div className="ScreenHomeVolunteer-container">
            <WebBar dispatch={props.dispatch} />
            <article className="content">
                <div
                    dangerouslySetInnerHTML={{
                        __html: globals.config.templates.volunteers,
                    }}
                />
                <Link className="button" to="/contact">
                    {i18n.contact}
                </Link>
            </article>
        </div>
    );
}

ScreenHomeVolunteer.contextTypes = contextTypes;

export default ScreenHomeVolunteer;
