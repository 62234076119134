export const overlays = {
    1: {
        id: "lofs",
        icon: "fab fa-searchengin",
        titleKey: "logicalFallacies",
        statsKey: "lofsCount",
        showInComment: true,
    },
    2: {
        id: "nvcs",
        icon: "fas fa-theater-masks",
        titleKey: "NVC",
        statsKey: "nvcsCount",
        showInComment: true,
    },
    3: {
        id: "hashTags",
        icon: "fas fa-hashtag",
        titleKey: "hashTags",
        statsKey: "hashTagsCount",
        showInComment: true,
        hide: true,
    },
};

export const userMenu = {
    manageDebates: {
        id: "manageDebates",
        icon: "fas fa-theater-masks",
        titleKey: "manage",
        isAdmin: true,
        hideMobile: true,
        href: "/admin",
    },
    glossary: {
        id: "glossary",
        icon: "fas fa-theater-masks",
        titleKey: "glossary",
        hideMobile: true,
        href: "/wiki",
    },
    logout: {
        id: "logout",
        icon: "fas fa-hashtag",
        titleKey: "logout",
        href: "/logout",
    },
};

export const sideMenu = {
    main: {
        id: "main",
        titleKey: "menuHome",
        href: "/",
    },
    about: {
        id: "about",
        titleKey: "menuAbout",
        href: "/about",
    },
    volunteers: {
        id: "volunteers",
        titleKey: "menuVolunteers",
        href: "/volunteers",
    },
    course: {
        id: "course",
        titleKey: "menuCourse",
        href: "/course",
    },
    wiki: {
        id: "wiki",
        titleKey: "menuWiki",
        href: "/wiki",
    },
    contact: {
        id: "contact",
        titleKey: "menuContact",
        href: "/contact",
    },
};

export const managerMenu = {
    debates: {
        id: "debates",
        icon: "dashboard",
        titleKey: "menuDebates",
        href: "/admin/debates",
    },
    pendings: {
        id: "pendings",
        icon: "view_list",
        titleKey: "menuPendings",
        href: "/admin/pending",
    },
    cc: {
        id: "cc",
        icon: "far fa-closed-captioning",
        titleKey: "menuCCStudio",
        href: "/admin/cc",
    },
    personas: {
        id: "personas",
        icon: "person",
        titleKey: "menuPersonas",
        href: "/admin/personas",
    },
    tags: {
        id: "tags",
        icon: "label",
        titleKey: "menuTags",
        href: "/admin/tags",
    },
    examples: {
        id: "examples",
        icon: "extension",
        titleKey: "menuExamples",
        href: "/admin/examples",
    },
    wiki: {
        id: "wiki",
        icon: "bookmark_border",
        titleKey: "menuWiki",
        href: "/wiki",
    },
    cards: {
        id: "cards",
        icon: "view_carousel",
        titleKey: "menuCards",
        href: "/admin/cards",
        disabled: true,
        hide: true,
    },
    dumps: {
        id: "dumps",
        icon: "cloud_download",
        titleKey: "menuDumps",
        href: "/admin/dump",
        users: [
            "k3XMQ3Wu5meNxTYshiRcPtz02gs1",
            "JKy2JQ4AmkMTJIdSOME3JDOaYbk1",
            "b0WoT1VvTjRc96aRoAU324EeeBv2",
        ],
    },
};
