import { getString, getJson } from "../utils/storage";
import config from "../config/index";

export default {
    appState: {
        isLoading: true,
        currentIds: {
            debId: 0,
            commentId: "i0",
            sentenceId: -1,
            tagId: 0,
        },
        viewIds: {
            adminDebatesId: getString("adminDebatesId") || "waitingForTagging",
            adminPendingId: getString("adminPendingId") || "pending",
        },
        selectedSentences: {},
        tree: {},
        overlay: "lofs",
        user: null,
        toast: null,
        xray: false,
        xrayEdit: false,
        editMode: false,
        isAdmin: false,
        showBack: true,
        role: null,
        barMode: "",
    },
    bons: {
        "7e2b6eb0": {
            lofs: {},
            nvcs: {},
            lofsX: {},
            nvcsX: {},
        },
    },
    contacts: {},
    debates: {},
    pendings: {},
    dumps: {},
    examples: {
        lofs: {},
        nvcs: {},
        lofsX: {},
        nvcsX: {},
    },
    sessions: {},
    tags: {
        lofs: {},
        nvcs: {},
        lofsX: {},
        nvcsX: {},
    },
    builder: {
        debate: getJson("builder_debate") || config.defaultDebate,
    },
    modal: {},
};
