import React from "react";
import ReactDOM from "react-dom";
import "./config/dependencies";
import globals from "./utils/globals";
import "./utils/dayjs";
import _App from "./components/App";
import * as serviceWorker from "./utils/serviceWorker";
import { Provider } from "react-redux";
import { initStore } from "./spark_modules/store";
import { withSelector } from "./utils/withSelector";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as analytics from "./utils/analytics";
import initialStates from "./spark_modules/initialStates.dev";
import thunks from "./spark_modules/sparkThunks";
import { navigateHomeIfNotAllowed } from "./utils/admin";
import "./styles/All.scss";

const history = createBrowserHistory();
globals.history = history;

history.listen(params => {
    const { pathname } = params || {};
    navigateHomeIfNotAllowed(pathname);
    analytics.addView(pathname);
});

const store = initStore(initialStates);

const App = withSelector("I", thunks)(_App);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
