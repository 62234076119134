export default {
    hashTags: "תגיות",
    logicalFallacies: "כשללים",
    NVC: "תקשומק",
    outerLink: "קישור חיצוני",
    explanationLink: "הגדרה",
    tagNew: "תגית חדשה",
    lofNew: "כשלל חדש",
    nvcNew: "תקשומק חדשה",
    lofXray:
        "טיעון שנמצא בטקסט. נא להימנע מטיעונים כפולים ע״י מעבר על הטיעונים הקיימים.",
    nvcXray: "ניסוח מחדש ברוח התקשומק",
    loginFacebook: "כניסה באמצעות פייסבוק",
    loginEmail: "כניסה באמצעות אימייל",
    logout: "יציאה מהחשבון",
    startingDate: "תאריך התחלה",
    status: "סטטוס",
    back: "חזרה",
    duration: "זמן ריצה",
    languages: "שפות",
    webLink: "אתר",
    facebookLink: "עמוד פייסבוק",
    medium: "מדיום",
    nextGoal: "יעד הבא",
    numberOfParticipants: "משתתפים",
    hoursTotal: "שעות שהושקעו",
    soon: "בקרוב",
    running: "באוויר",
    future: "בעתיד",
    hours: "שעות",
    next: "הבא",
    previous: "הקודם",
    infoTable: "טבלת מידע",
    list: "רשימה",
    preview: "תצוגה מקדימה",
    debateDetails: "פרטי הדיון",
    participants: "משתתפים",
    commentDetails: "פרטי התגובה",
    arguments: "טיעונים",
    fieldId: "מזהה",
    fieldImageUrl: "תמונה",
    fieldParticipantId: "מזהה משתמש",
    fieldTitle: "כותרת",
    fieldCommentId: "מזהה תגובה",
    fieldTagId: "תגית",
    fieldExplanationId: "הסבר",
    fieldContentId: "תוכן",
    fieldLikes: "מספר לייקים",
    fieldOrder: "סדר הופעה",
    magicSure: "האם את/ה בטוח/ה שברצונך למחוק?",
    magicNew: "הוספת חדש",
    magicSave: "שמירה",
    magicTitle: "כותרת/שם",
    emptyList: "רשימה ריקה",
    noItems: "אין פריטים להציג",
    viewMode: "תצוגה מקדימה",
    editMode: "עריכה",
    edit: "עריכה",
    delete: "מחיקה",
    manage: "ממשק ניהול",
    manageTags: "ניהול תגיות",
    glossary: "מילון מונחים",
    manageDebates: "ניהול דיונים",
    menuDebates: "דיונים",
    menuPendings: "ממתינים",
    menuCCStudio: "אולפן כתוביות",
    menuPersonas: "פרסונות",
    menuTags: "תגיות",
    menuCards: "קלפים",
    menuPeople: "משתמשים",
    menuExamples: "דוגמאות",
    menuDumps: "דאמפס",
    tableLink: "לינק",
    tableTitle: "כותרת",
    tableType: "סוג",
    tableSource: "מקור",
    tableTextLength: "אורך\nטקסט",
    tableMainArguments: "טיעונים\nמרכזיים",
    tableTagLofs: "תיוג\nכשללים",
    tablePublished: "פורסם",
    tableReviewTime: "זמן סקירה",
    tableMinutes: "דקות",
    tableInbounds: "שלטים",
    tableVisits: "כניסות",
    tableAverageTime: "זמן\nממוצע",
    tableSeconds: "שניות",
    tableTimePerCharacter: "זמן\nלתו",
    tableMillis: "מיל׳",
    tableDebateDate: "תאריך\nהדיון",
    tableImportDate: "תאריך\nייבוא",
    tablePublishDate: "תאריך\nפרסום",
    tableImageUrl: "תמונה",
    tableFacebookUrl: "פייס",
    tableTwitterUrl: "טוויטר",
    tableInstagramUrl: "איסטגרם",
    tableTagTitle: "תגית",
    tableWikiUrl: "ויקי",
    tableCardImageUrl: "תמונה",
    tableAuthorImageUrl: "תמונה",
    tableCardTopic: "נושא",
    tableIndex: "מזהה",
    tableEnglish: "אנגלית",
    tableHebrew: "עברית",
    tableDescription: "תיאור",
    tableLofs: "כשללים",
    tableExamplesCount: "דוגמאות",
    tableDescriptionLength: "אורך תיאור",
    tableDescriptionLengthUnits: "תווים",
    tableWikiReady: "הגדרה\nמוכנה",
    tableCardReady: "קלף\nמוכן",
    tableDoneSubtitles: "כתוביות\n מוכנות",
    tableName: "שם",
    tableDate: "תאריך",
    tableComments: "תגובות",
    tableLikes: "לייקים",
    tableShares: "שיתופים",
    tableLength: "אורך",
    tableFields: "שדות",
    tableVideo: "וידאו",
    tableSnooze: "סנוז",
    tableSize: "גודל",
    tableMedia: "קלף",
    tableIsDeleted: "סימון\nלמחיקה",
    menuAbout: "אודות הפרוייקט",
    menuVolunteers: "מתנדבים",
    menuCourse: "קורס באומנות הויכוח",
    menuWiki: "מילון מונחים",
    menuContact: "יצירת קשר",
    menuHome: "דף הבית",
    version: "גירסה",
    statsDebatesTotal: "סה״כ",
    statsDebatesParsed: "נסקרו",
    statsDebatesPublished: "פורסמו",
    statsDebatesNew: "חדשים",
    statsPendingsTotal: "סה״כ",
    statsPendingsVideo: "וידאו",
    statsPendingsImage: "תמונה",
    statsPendingsText: "טקסט",
    statsCcTotal: "סה״כ",
    statsCcYoutube: "יוטיוב",
    statsCcFacebook: "פייסבוק",
    statsPersonasTotal: "סה״כ",
    statsTagsTotal: "סה״כ",
    statsTagsLofs: "כשללים",
    statsTagsNvcs: "תקשומק",
    statsExamplesTotal: "סה״כ",
    statsPendingsSnoozed: "סנוז",
    statsExamplesLofs: "כשללים",
    statsExamplesNvcs: "תקשומק",
    stepDebateKind: "בחירת סוג הדיון",
    stepDebateContent: "הזנת תוכן",
    stepDebateCC: "פרסום",
    findThumbSearchExists: "יש תמונה",
    findThumbSearchOk: "תמונה נמצאה",
    findThumbSearchError: "לא נמצאה",
    findCCfetchOk: "כתוביות",
    findCCfetchError: "לא נמצאו",
    bonIsRequired: "יש לספק ערך לפרמטר התגית",
    bonCreateError: "שגיאה בהוספת תגית חדשה",
    bonCreateExists: "תגית כבר קיימת עם הערכים הללו",
    bonCreateOk: "תגית נוספה בהצלחה",
    bonReadError: "שגיאה בקריאת תגית",
    bonUpdateRequired: "יש לספק ערך לפרמטר התגית",
    bonUpdateError: "שגיאה בעדכון תגית",
    bonDeleteError: "שגיאה במחיקת תגית",
    bonsReadError: "שגיאה בקריאת תגיות",
    bonUpdateNoPermissions: "אין לך הרשאות לשינוי תגית",
    bonCreateNoPermissions: "אין לך הרשאות ליצירת תגית",
    bonDeleteNoPermissions: "אין לך הרשאות למחיקת תגית",
    contactIsRequired: "יש לספק ערך לפרמטר איש הקשר",
    contactCreateError: "שגיאה בהוספת איש קשר חדש",
    contactCreateExists: "איש קשר כבר קיים עם הערכים הללו",
    contactCreateOk: "איש קשר נוסף בהצלחה",
    contactReadError: "שגיאה בקריאת איש קשר",
    contactUpdateRequired: "יש לספק ערך לפרמטר איש הקשר",
    contactUpdateError: "שגיאה בעדכון איש קשר",
    contactDeleteError: "שגיאה במחיקת איש קשר",
    contactsReadError: "שגיאה בקריאת אנשי הקשר",
    contactUpdateNoPermissions: "אין לך הרשאות לעדכן איש קשר",
    contactCreateNoPermissions: "אין לך הרשאות ליצירת איש קשר",
    contactDeleteNoPermissions: "אין לך הרשאות למחיקת איש קשר",
    debateIsRequired: "יש לספק ערך לפרמטר הדיון",
    debateCreateError: "שגיאה בהוספת דיון חדש",
    debateCreateExists: "דיון כבר קיים עם הערכים הללו",
    debateCreateOk: "דיון נוסף בהצלחה!",
    debateReadError: "שגיאה בקריאת דיון",
    debateUpdateRequired: "יש לספק ערך לפרמטר הדיון",
    debateUpdateError: "שגיאה בעדכון דיון",
    debateDeleteError: "שגיאה במחיקת דיון",
    debatesReadError: "שגיאה בקריאת דיונים",
    debateUpdateNoPermissions: "אין לך הרשאות לעדכן דיון",
    debateCreateNoPermissions: "אין לך הרשאות ליצירת דיון",
    debateDeleteNoPermissions: "אין לך הרשאות למחיקת דיון",
    debateStatsIsRequired: "יש לספק ערך לפרמטר הסטטיסטיקה",
    debateStatsReadError: "שגיאה בקריאת סטטיסטיקה",
    debateStatsUpdateRequired: "יש לספק ערך לפרמטר הסטטיסטיקה",
    debateStatsUpdateError: "שגיאה בעדכון סטטיסטיקה",
    debateStatsDeleteError: "שגיאה במחיקת סטטיסטיקה",
    debateStatsUpdateNoPermissions: "אין לך הרשאות לעדכן סטטיסטיקה",
    debateStatsDeleteNoPermissions: "אין לך הרשאות למחיקת סטטיסטיקה",
    debateInfoIsRequired: "יש לספק ערך לפרמטר האינפו",
    debateInfoReadError: "שגיאה בקריאת אינפו",
    debateInfoUpdateRequired: "יש לספק ערך לפרמטר האינפו",
    debateInfoUpdateError: "שגיאה בעדכון אינפו",
    debateInfoDeleteError: "שגיאה במחיקת אינפו",
    debateInfoUpdateNoPermissions: "אין לך הרשאות לעדכן אינפו",
    debateInfoDeleteNoPermissions: "אין לך הרשאות למחיקת אינפו",
    debateProgressIsRequired: "יש לספק ערך לפרמטר ההתקדמות",
    debateProgressReadError: "שגיאה בקריאת התקדמות",
    debateProgressUpdateRequired: "יש לספק ערך לפרמטר ההתקדמות",
    debateProgressUpdateError: "שגיאה בעדכון התקדמות",
    debateProgressDeleteError: "שגיאה במחיקת התקדמות",
    debateProgressUpdateNoPermissions: "אין לך הרשאות לעדכן התקדמות",
    debateProgressDeleteNoPermissions: "אין לך הרשאות למחיקת התקדמות",
    debateRootIsRequired: "יש לספק ערך לפרמטר הבסיס",
    debateRootReadError: "שגיאה בקריאת בסיס",
    debateRootUpdateRequired: "יש לספק ערך לפרמטר הבסיס",
    debateRootUpdateError: "שגיאה בעדכון בסיס",
    debateRootDeleteError: "שגיאה במחיקת בסיס",
    debateRootsReadError: "שגיאה בקריאת בסיסים",
    debateRootUpdateNoPermissions: "אין לך הרשאות לעדכן בסיס",
    debateRootDeleteNoPermissions: "אין לך הרשאות למחיקת בסיס",
    exampleIsRequired: "יש לספק ערך לפרמטר הדוגמה",
    exampleCreateError: "שגיאה בהוספת דוגמה חדשה",
    exampleCreateExists: "דוגמה כבר קיימת עם הערכים הללו",
    exampleCreateOk: "דוגמה נוספה בהצלחה!",
    exampleReadError: "שגיאה בקריאת דוגמה",
    exampleUpdateRequired: "יש לספק ערך לפרמטר הדוגמה",
    exampleUpdateError: "שגיאה בעדכון דוגמה",
    exampleDeleteError: "שגיאה במחיקת דוגמה",
    examplesReadError: "שגיאה בקריאת דוגמאות",
    exampleUpdateNoPermissions: "אין לך הרשאות לשינוי דוגמה",
    exampleCreateNoPermissions: "אין לך הרשאות ליצירת דוגמה",
    exampleDeleteNoPermissions: "אין לך הרשאות למחיקת דוגמה",
    participantIsRequired: "יש לספק ערך לפרמטר המשתתף",
    participantCreateError: "שגיאה בהוספת משתתף חדש",
    participantCreateExists: "משתתף כבר קיים עם הערכים הללו",
    participantCreateOk: "משתתף נוסף בהצלחה!",
    participantReadError: "שגיאה בקריאת משתתף",
    participantUpdateRequired: "יש לספק ערך לפרמטר המשתתף",
    participantUpdateError: "שגיאה בעדכון משתתף",
    participantDeleteError: "שגיאה במחיקת משתתף",
    participantsReadError: "שגיאה בקריאת משתתפים",
    participantUpdateNoPermissions: "אין לך הרשאות לעדכן משתתף",
    participantCreateNoPermissions: "אין לך הרשאות ליצירת משתתף",
    participantDeleteNoPermissions: "אין לך הרשאות למחיקת משתתף",
    sessionIsRequired: "יש לספק ערך לפרמטר הסשן",
    sessionCreateError: "שגיאה בהוספת סשן חדש",
    sessionCreateViewError: "שגיאה בהוספת צפיית סשן חדשה",
    sessionCreateExists: "סשן כבר קיים עם הערכים הללו",
    sessionCreateOk: "סשן נוסף בהצלחה!",
    sessionReadError: "שגיאה בקריאת סשן",
    sessionUpdateRequired: "יש לספק ערך לפרמטר הסשן",
    sessionUpdateError: "שגיאה בעדכון סשן",
    sessionDeleteError: "שגיאה במחיקת סשן",
    sessionsReadError: "שגיאה בקריאת סשנים",
    sessionUpdateNoPermissions: "אין לך הרשאות לשינוי סשן",
    sessionCreateNoPermissions: "אין לך הרשאות ליצירת סשן",
    sessionDeleteNoPermissions: "אין לך הרשאות למחיקת סשן",
    tagIsRequired: "יש לספק ערך לפרמטר התגית",
    tagCreateError: "שגיאה בהוספת תגית חדשה",
    tagCreateExists: "תגית כבר קיימת עם הערכים הללו",
    tagCreateOk: "תגית נוספה בהצלחה!",
    tagReadError: "שגיאה בקריאת תגית",
    tagUpdateRequired: "יש לספק ערך לפרמטר התגית",
    tagUpdateError: "שגיאה בעדכון תגית",
    tagDeleteError: "שגיאה במחיקת תגית",
    tagsReadError: "שגיאה בקריאת תגיות",
    tagtUpdateNoPermissions: "אין לך הרשאות לשינוי תגית",
    tagtCreateNoPermissions: "אין לך הרשאות ליצירת תגית",
    tagtDeleteNoPermissions: "אין לך הרשאות למחיקת תגית",
    voteIsRequired: "יש לספק ערך לפרמטר ההצבעה",
    voteCreateError: "שגיאה בהוספת הצבעה חדשה",
    voteCreateExists: "הצבעה כבר קיימת עם הערכים הללו",
    voteCreateOk: "הצבעה נוספה בהצלחה!",
    voteReadError: "שגיאה בקריאת הצבעה",
    voteUpdateRequired: "יש לספק ערך לפרמטר ההצבעה",
    voteUpdateError: "שגיאה בעדכון הצבעה",
    voteDeleteError: "שגיאה במחיקת הצבעה",
    votesReadError: "שגיאה בקריאת הצבעות",
    voteUpdateNoPermissions: "אין לך הרשאות לשינוי הצבעה",
    voteCreateNoPermissions: "אין לך הרשאות ליצירת הצבעה",
    voteDeleteNoPermissions: "אין לך הרשאות למחיקת הצבעה",
    commentIsRequired: "יש לספק ערך לפרמטר התגובה",
    commentCreateError: "שגיאה בהוספת תגובה חדשה",
    commentCreateExists: "תגובה כבר קיימת עם הערכים הללו",
    commentCreateOk: "תגובה נוספה בהצלחה!",
    commentReadError: "שגיאה בקריאת תגובה",
    commentUpdateRequired: "יש לספק ערך לפרמטר התגובה",
    commentUpdateError: "שגיאה בעדכון תגובה",
    commentDeleteError: "שגיאה במחיקת תגובה",
    commentsReadError: "שגיאה בקריאת תגובות",
    commentUpdateNoPermissions: "אין לך הרשאות לשינוי תגובה",
    commentCreateNoPermissions: "אין לך הרשאות ליצירת תגובה",
    commentDeleteNoPermissions: "אין לך הרשאות למחיקת תגובה",
    personasIsRequired: "יש לספק ערך לפרמטר הפרסונה",
    personasCreateError: "שגיאה בהוספת פרסונה חדש",
    personasCreateExists: "פרסונה כבר קיים עם הערכים הללו",
    personasCreateOk: "פרסונה נוסף בהצלחה!",
    personasReadError: "שגיאה בקריאת פרסונה",
    personasUpdateRequired: "יש לספק ערך לפרמטר הפרסונה",
    personasUpdateError: "שגיאה בעדכון פרסונה",
    personasDeleteError: "שגיאה במחיקת פרסונה",
    personassReadError: "שגיאה בקריאת הפרסונות",
    personasUpdateNoPermissions: "אין לך הרשאות לעדכן פרסונה",
    personasCreateNoPermissions: "אין לך הרשאות ליצירת פרסונה",
    personasDeleteNoPermissions: "אין לך הרשאות למחיקת פרסונה",
    pendingIsRequired: "יש לספק ערך לפרמטר ההצעה",
    pendingCreateError: "שגיאה בהוספת הצעה חדשה",
    pendingCreateExists: "הצעה כבר קיימת עם הערכים הללו",
    pendingCreateOk: "הצעה נוספה בהצלחה!",
    pendingReadError: "שגיאה בקריאת הצעה",
    pendingUpdateRequired: "יש לספק ערך לפרמטר ההצעה",
    pendingUpdateError: "שגיאה בעדכון הצעה",
    pendingDeleteError: "שגיאה במחיקת הצעה",
    pendingsReadError: "שגיאה בקריאת הצעות",
    pendingReadNoPermissions: "אין לך הרשאות לקרוא הצעה",
    pendingUpdateNoPermissions: "אין לך הרשאות לעדכן הצעה",
    pendingCreateNoPermissions: "אין לך הרשאות ליצירת הצעה",
    pendingDeleteNoPermissions: "אין לך הרשאות למחיקת הצעה",
    pendingPromoteNoPermissions: "אין לך הרשאות לקדם הצעה",
    pendingPromoteNotFound: "לא נמצאה הצעה לקידום",
    pendingPromoteFailed: "שגיאה בקידום ההצעה",
    dumpIsRequired: "יש לספק ערך לפרמטר הדאמפ",
    dumpCreateError: "שגיאה בהוספת דאמפ חדשה",
    dumpCreateOk: "דאמפ נוספה בהצלחה!",
    dumpReadError: "שגיאה בקריאת דאמפ",
    dumpUpdateRequired: "יש לספק ערך לפרמטר הדאמפ",
    dumpUpdateError: "שגיאה בעדכון דאמפ",
    dumpDeleteError: "שגיאה במחיקת דאמפ",
    dumpsReadError: "שגיאה בקריאת הצעות",
    dumpReadNoPermissions: "אין לך הרשאות לקרוא דאמפ",
    dumpUpdateNoPermissions: "אין לך הרשאות לעדכן דאמפ",
    dumpCreateNoPermissions: "אין לך הרשאות ליצירת דאמפ",
    dumpDeleteNoPermissions: "אין לך הרשאות למחיקת דאמפ",
    dumpPromoteNoPermissions: "אין לך הרשאות לקדם דאמפ",
    dumpPromoteNotFound: "לא נמצאה דאמפ לקידום",
    dumpPromoteFailed: "שגיאה בקידום הדאמפ",
    adminStatsFailed: "שגיאה בחישוב סטטיסטיקה",
    adminStatsNoPermissions: "אין לך הרשאות לסטטיסטיקה",
    fileUploadError: "אין לך הרשאות ליצור קובץ",
    ccRequiredError: "יש לספק ערך לכתוביות",
    ccDownloadError: "שגיאה בהורדת כתוביות",
    ccParseError: "שגיאה בפרסור כתוביות",
    ccNoPermissions: "אין לך הרשאה לפרסר  לכתוביות",
    optionSizeSingle: "פוסט יחיד",
    optionSizeFull: "דיון מלא",
    optionMediaText: "פוסט",
    optionMediaImage: "תמונה",
    optionMediaVideo: "סרטון",
    optionLayerLofs: "כשללים",
    optionLayerNvcs: "תקשורת מקרבת",
    optionLayerSubtext: "סאבטקסט",
    optionLayerFactCheck: "עובדות",
    optionFlavourTagging: "תיוג",
    optionFlavourRephrasing: "ניסוח מחדש",
    optionSourcesFacebook: "פייסבוק",
    optionSourcesTwitter: "טוויטר",
    optionSourcesYoutube: "יוטיוב",
    optionViewDebatesPreTag: "מחכים לתיוג",
    optionViewDebatesPrePublish: "מחכים לפרסום",
    optionViewDebatesPublished: "באוויר",
    optionViewDebatesPreRemove: "מסומנים למחיקה",
    optionViewDebatesAll: "כל הדיונים",
    optionViewCCText: "טקסט הסרטון",
    optionViewCCTiming: "הצמדת זמנים",
    optionViewCCTDone: "סיום הכנסת הכתוביות",
    buttonCCTimeSentence: "סימון משפט",
    buttonCCSlow: "שינוי מהירות",
    buttonCCForward5: "קדימה 5 שניות",
    buttonCCPlay: "ניגון",
    buttonCCBack5: "חזרה 5 שניות",
    breadcrumbsManager: "ניהול",
    breadcrumbsDebates: "דיונים",
    breadcrumbsAddDebate: "הוספת דיון",
    breadcrumbsAddPersona: "הוספת פרסונה",
    breadcrumbsAddTag: "הוספת תגית",
    breadcrumbsAddCC: "הוספת כתוביות",
    breadcrumbsAddExample: "הוספת דוגמה",
    breadcrumbsSubtitles: "כתוביות",
    breadcrumbsExamples: "דוגמאות",
    breadcrumbsPersonas: "פרסונות",
    breadcrumbsPending: "ממתינים",
    breadcrumbsDumps: "מועמדים",
    breadcrumbsTags: "תגיות",
    breadcrumbsWikis: "מונחים",
    breadcrumbsOneByOne: "מעבר אחד-אחד",
    placeholderSizes: "גודל השרשור",
    placeholderLayers: "סוג השכבה",
    placeholderFlavours: "מה עושים?",
    placeholderSources: "מקור",
    placeholderCardImage: "תמונת קלף",
    placeholderCardImageRatio: "יחס התמונה",
    placeholderImage: "תמונת ראשית",
    placeholderImageRatio: "יחס התמונה",
    placeholderAuthorImage: "תמונת הכותב",
    placeholderAuthorImageRatio: "יחס התמונה",
    placeholderCardTopic: "נושא קלף",
    placeholderPersona: "דמות",
    labelVideoUrl: "סרטון יוטיוב או פייסבוק",
    videoInstructions: "אחרי שהוידאו מתחיל יש לצאת ממצב ״מסך מלא״",
    examplesTags: "דוגמאות לתגיות",
    examplesTag: "דוגמאות ל",
    exampleAdd: "הוספת דוגמה",
    exampleAddOk: "דוגמה נוספה בהצלחה",
    exampleSave: "שמירת הדוגמה",
    exampleAdded: "דוגמה נוספה בהצלחה",
    debateAdded: "הדיון נוסף בהצלחה",
    debateSavedOk: "הדיון נשמר בהצלחה",
    debateSavedError: "בעיה בשמירת הדיון",
    exampleSaveOk: "דוגמה נשמרה בהצלחה",
    exampleDeletedOk: "דוגמה נמחקה בהצלחה",
    personaAddOk: "הפרסונה נוספה בהצלחה",
    personaSaveOk: "הפרסונה עודכנה בהצלחה",
    personaDeletedOk: "הפרסונה נמחקה בהצלחה",
    tagSaveOk: "תגית נשמרה בהצלחה",
    tagDeletedOk: "תגית הוסרה בהצלחה",
    dumpTitle: "ניפוי ראשוני",
    items: "פריטים",
    clear: "איפוס",
    placeholderPersonaName: "שם הפרסונה",
    placeholderPersonaImage: "תמונה",
    placeholderPersonaFacebook: "פייסבוק",
    placeholderPersonaTwitter: "טוויטר",
    placeholderPersonaInstagram: "אינסטגרם",
    placeholderPersonaWiki: "ויקיפדיה",
    personaAdd: "הוספת פרסונה",
    savePersona: "שמירת הפרסונה",
    placeholderTag: "תגית",
    placeholderDescription: "הסבר",
    placeholderExplanation: "הסבר",
    placeholderTitle: "שם",
    placeholderSignExists: "הוצב שלט",
    areYouSure: "האם את/ה בטוח?",
    contactAdded: "הפרטים נשמרו בהצלחה!",
    tagAdd: "הוספת תגית",
    tagAddOk: "תגית נוספה בהצלחה",
    tagSave: "שמירת תגית",
    imageCalcRatio: "חשב יחס וגודל",
    tableEdit: "עריכה",
    bonSave: "שמירת התגית",
    bonSavedOk: "תגית נשמרה בהצלחה",
    bonDeletedOk: "תגית הועברה לסל המחזור",
    formName: "שם פרטי",
    formEmailOrPhone: "טלפון או אימייל",
    formNotes: "הודעה (אופציונלי)",
    formSend: "שליחת פרטים",
    caeserEmpty: "אין כרגע דיונים בתור",
    loginMissingFields: "יש להזין אימייל וסיסמא",
    loginError: "שגיאה בפרטי ההתחברות",
    loginTitle: "מאגר הדיונים של האינטרנט",
    loginSubtitle: "כניסה לחשבון",
    placeholderEmail: "אימייל",
    placeholderPassword: "סיסמה",
    loginButtonText: "כניסה",
    wikiExample: "דוגמה",
    wikiExamples: "דוגמאות",
    wikiChildren: "סוגים",
    webbarAbout: "אודות",
    webbarVolunteers: "מחפשים מתנדבים",
    webbarWiki: "מילון מונחים",
    webbarContact: "צור קשר",
    contact: "צור קשר",
    contactTitle: "יצירת קשר",
    ccStudioTitle: "אולפן כתוביות",
    view: "תצוגה",
    replies: "תגובות",
    emptyTable: "טבלה ריקה",
    adminHomeTitle: "ממשק ניהול",
    adminDebatesTitle: "דיונים במערכת",
    adminDebatesAdd: "הוספת דיון",
    adminDebatesSave: "הוספת הדיון",
    save: "שמירה",
    adminPersonasTitle: "פרסונות במערכת",
    adminPersonasAdd: "הוספת פרסונה",
    adminTagsTitle: "תגיות במערכת",
    adminTagsAdd: "הוספת תגית",
    adminPendingTitle: "דיונים ממתינים",
    card: "קלף",
    definition: "הגדרה",
    panel: "פאנל",
    linkToPage: "קישור לעמוד",
    caeserAdd: "הופסה לדיונים",
    caeserSnooze: "סנוז",
    caeserMarkForDeletion: "סימון למחיקה",
    caeserLeftDebates: "דיונים נשארו",
    add: "הוספה",
    wikiTags: "מונחים",
    editBonModal: "עריכת תגית",
    editExampleModal: "עריכת דוגמה",
    editTagModal: "עריכת תגית",
    editPersonaModal: "עריכת פרסונה",
    addTagModal: "הוספת תגית",
    addPersonaModal: "הוספת פרסונה",
    addExampleModal: "הוספת דוגמה",
    missingParameters: "חסרים פרמטרים",
};
