import { lazy } from "react";
import { withSelector } from "../utils/withSelector";
import thunks from "../spark_modules/sparkThunks";

import ScreenRoot  from "../layout_modules/screen-root/ScreenRoot"; // prettier-ignore
import ScreenPanel from "../layout_modules/screen-panel/ScreenPanel"; // prettier-ignore
import ScreenBrowse from "../layout_modules/screen-browse/ScreenBrowse"; // prettier-ignore

const ScreenCourse = lazy(() => import( "../layout_modules/screen-course/ScreenCourse")); // prettier-ignore
const ScreenContact = lazy(() => import( "../layout_modules/screen-home/ScreenHomeContact")); // prettier-ignore
const ScreenCards = lazy(() => import( "../layout_modules/screen-cards/ScreenCards")); // prettier-ignore
const ScreenWiki = lazy(() => import( "../layout_modules/screen-wiki/ScreenWiki")); // prettier-ignore
const ScreenWikiHome = lazy(() => import( "../layout_modules/screen-wiki-home/ScreenWikiHome")); // prettier-ignore
const Admin = lazy(() => import( "../components/Admin")); // prettier-ignore

export default {
    root: withSelector("ScreenRoot", thunks)(ScreenRoot), // prettier-ignore
    course: withSelector("I", thunks)(ScreenCourse), // prettier-ignore
    panel: withSelector("ScreenPanel", thunks)(ScreenPanel), // prettier-ignore
    cards: withSelector("ScreenCards", thunks)(ScreenCards), // prettier-ignore
    wikiHome: withSelector("ScreenWikiHome", thunks)(ScreenWikiHome), // prettier-ignore
    wiki: withSelector("Wiki", thunks)(ScreenWiki), // prettier-ignore
    contact: withSelector("I", thunks)(ScreenContact), // prettier-ignore
    browse: withSelector("ScreenBrowse", thunks)(ScreenBrowse), // prettier-ignore
    admin: withSelector("I", thunks)(Admin) // prettier-ignore
};
