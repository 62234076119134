// @flow
import React, { Component } from "react";
import "./Egg.scss";
import contextTypes from "../contextTypes";
import globals from "../../utils/globals";

export class Egg extends Component {
    state = {
        egg: "-",
    };

    async componentDidMount() {
        const res = await globals.fetch("/egg");
        const { egg } = res || {};

        this.setState({ egg });
    }

    render() {
        const { egg } = this.state;

        return <div className="Egg-container">{egg}</div>;
    }
}

Egg.contextTypes = contextTypes;

export default Egg;
