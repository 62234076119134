import * as navigation from "./navigation";
import globals from "./globals";

export const checkIfAllowed = pathname => {
    const routes = globals.config.routes || {};
    const route = routes[pathname];

    if (!route) return true;

    const { isAdmin } = route || {};

    return !isAdmin || isAdmin === globals.isAdmin;
};

const isDev = process.env.NODE_ENV === "development";

export const navigateHomeIfNotAllowed = pathname => {
    const isAllowed = checkIfAllowed(pathname || {});

    if (!isAllowed && !isDev) {
        navigation.toHome();
    }
};
