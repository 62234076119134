let history, i18n, defaults, isAdmin, config, _fetch, api, firebase, components;

export default {
    get history() {
        return history;
    },
    set history(value) {
        history = value;
    },
    get i18n() {
        return i18n;
    },
    set i18n(value) {
        i18n = value;
    },
    get defaults() {
        return defaults;
    },
    set defaults(value) {
        defaults = value;
    },
    get isAdmin() {
        return isAdmin;
    },
    set isAdmin(value) {
        isAdmin = value;
    },
    get config() {
        return config;
    },
    set config(value) {
        config = value;
    },
    get fetch() {
        return _fetch;
    },
    set fetch(value) {
        _fetch = value;
    },
    get api() {
        return api;
    },
    set api(value) {
        api = value;
    },
    get firebase() {
        return firebase;
    },
    set firebase(value) {
        firebase = value;
    },
    get components() {
        return components;
    },
    set components(value) {
        components = value;
    },
};
