import actions from "./sparkActions";
import thunks from "./thunks/index";

export default dispatch => {
    const dispatchP = action => {
        return new Promise((resolve, reject) => {
            action.resolve = resolve;
            action.reject = reject;
            dispatch(action);
        });
    };

    return {
        dispatch: action => {
            return dispatch(action);
        },
        dispatchP: action => {
            return dispatchP(action);
        },
        signIn: (email, password) => {
            return dispatchP({ type: "SIGN_IN", email, password });
        },
        signOut: () => {
            dispatch({ type: "SIGN_OUT" });
            localStorage.clear();
        },
        showToast: (text, type = "success") => {
            dispatch(actions.showToast(text, type));
        },
        ...thunks(dispatch, dispatchP),
    };
};
