// @flow
import React, { Component } from "react";
import "./ScreenPost.scss";
import contextTypes from "../contextTypes";
import throttle from "lodash/throttle";
import TextualPost from "../post/TextualPost";
import VideoPost from "../video-post/VideoPost";
import scroll from "../../utils/scroll";
import { withRouter } from "react-router-dom";
import DefinitionModal from "../definition-modal/DefinitionModal";
import PanelModal from "../panel-modal/PanelModal";
import classnames from "classnames";
import ScreenPanel from "../screen-panel/ScreenPanel";
import globals from "../../utils/globals";

export class ScreenPost extends Component {
    state = {
        isSticky: false,
    };

    constructor() {
        super();

        this.onScroll = throttle(this.onScroll.bind(this), 100);
    }

    get debate() {
        const { debate } = this.props;
        return debate || {};
    }

    get root() {
        const { root } = this.debate;
        return root || {};
    }

    get isVideo() {
        const { info } = this.debate || {},
            { size, media } = info || {};

        return media === "video" && size === "full";
    }

    get style() {
        const { isMobile } = this.context;
        const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

        if (isMobile) {
            return {};
        }

        return { paddingBottom: height - 100 };
    }

    onScroll() {
        const scrollTop = scroll.top;

        this.setState({ isSticky: scrollTop > 100 });
    }

    keyDown = ev => {
        const { commentId, isInputFocused } = this.props;

        if (isInputFocused || globals.isAdmin) {
            return;
        }

        switch (ev.which) {
            case 38:
                ev.preventDefault();
                this.props.changeCommentId(Math.max(commentId - 1, 1));
                this.scrollToSelected();
                break;
            case 40:
                ev.preventDefault();
                this.props.changeCommentId(commentId + 1);
                this.scrollToSelected();
                break;
            default:
                break;
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll);
        document.addEventListener("keydown", this.keyDown);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
        document.removeEventListener("keydown", this.keyDown);
    }

    scrollToSelected = () => {
        const el = document.querySelector(".Comment-container.selected");
        scroll.toEl(el, true, -50);
    };

    onCommentClick = (el, item) => {
        const { isMobile } = this.context;
        this.props.changeCommentId(item.id);

        if (isMobile) {
            return;
        }

        // scroll.toEl(el, true, -50);
    };

    changeSentence = (ev, id) => {
        scroll.toEl(ev.target, true, -60);
        this.props.changeSentenceId(id);
    };

    onAvatarClick = (el, item) => {
        const { debate } = this.props;
        this.props.onAvatarClick(debate.id, item.id);
    };

    onPostClick = () => {
        this.props.changeCommentId("i0");
        this.navToComment(0);
        scroll.to(0, true);
    };

    renderPanel = () => {
        return (
            <div className="widget-panel animated slideInUp">
                <ScreenPanel {...this.props} />
            </div>
        );
    };

    renderWiki = () => {
        return <div className="widget-wiki">wiki</div>;
    };

    renderOverlay = () => {
        const { isMobile } = this.context;

        if (!isMobile) return;

        return (
            <div className="overlay">
                {this.renderPanel()}
                {this.renderWiki()}
            </div>
        );
    };

    closeDefinition = () => {
        this.props.changeTagId(0);
    };

    closePanel = () => {
        this.props.changeSentenceId(-1);
    };

    renderDefinition = () => {
        const { tagId } = this.props;

        if (!tagId) return null;

        return <DefinitionModal onClose={this.closeDefinition} />;
    };

    renderPanel = () => {
        const { isMobile } = this.context;
        const { sentenceId } = this.props;

        if (!isMobile || sentenceId < 0) return null;

        return <PanelModal onClose={this.closePanel} />;
    };

    renderInner() {
        const { commentId, editMode, selectedSentences } = this.props;

        const Cmp = this.isVideo ? VideoPost : TextualPost;

        return (
            <Cmp
                editMode={editMode}
                commentId={commentId}
                selectedSentences={selectedSentences}
                onSentenceClick={this.props.changeSelectedSentences}
                changeSentence={this.changeSentence}
                onAvatarClick={this.onAvatarClick}
                onCommentClick={this.onCommentClick}
                onPostClick={this.onPostClick}
                changeCommentId={this.props.changeCommentId}
                debate={this.debate}
            />
        );
    }

    render() {
        const { isMobile } = this.context;
        const { isVideo } = this.props;

        const className = classnames("ScreenPost-container", {
            video: isVideo,
            mobile: isMobile,
        });

        if (!this.root || !this.root.id) return null;

        return (
            <div className={className}>
                <div className="inner" style={this.style}>
                    {this.renderInner()}
                </div>
                {this.renderPanel()}
                {this.renderDefinition()}
            </div>
        );
    }
}

ScreenPost.contextTypes = contextTypes;

export default withRouter(ScreenPost);
