import actions from "../sparkActions";
import * as storage from "../../utils/storage";

export default dispatch => {
    return {
        changeXray: xray => {
            dispatch(actions.patchAppState({ xray }));
        },
        changeEditMode: editMode => {
            dispatch(actions.patchAppState({ editMode }));
        },
        changeOverlay: overlay => {
            dispatch(actions.patchAppState({ overlay }));
        },
        changeViewId: (key, value) => {
            storage.setString(key, value);
            dispatch(actions.patchViewId({ [key]: value }));
        },
        changeDebateId: debId => {
            dispatch(actions.patchCurrentId({ debId }));
        },
        changeCommentId: commentId => {
            dispatch(actions.patchCurrentId({ commentId }));
        },
        changeExampleId: exampleId => {
            dispatch(actions.patchCurrentId({ exampleId }));
        },
        changeBonId: bonId => {
            dispatch(actions.patchCurrentId({ bonId }));
        },
        changeBonIdHover: bonId => {
            dispatch(actions.patchCurrentId({ bonIdHover: bonId }));
        },
        changePendingId: pendingId => {
            dispatch(actions.patchCurrentId({ pendingId }));
        },
        changePersonaId: personaId => {
            dispatch(actions.patchCurrentId({ personaId }));
        },
        changeDumpId: dumpId => {
            dispatch(actions.patchCurrentId({ dumpId }));
        },
        changeTagId: tagId => {
            dispatch(actions.patchCurrentId({ tagId }));
        },
        changeFilterTagId: filterTagId => {
            dispatch(actions.patchCurrentId({ filterTagId }));
        },
        changeBarMode: barMode => {
            dispatch(actions.patchAppState({ barMode }));
        },
        changeFocus: focus => {
            dispatch(actions.patchAppState({ isInputFocused: focus }));
        },
        changeSentenceId: sentenceId => {
            dispatch(actions.patchCurrentId({ sentenceId }));
        },
        changeSelectedSentences: selectedSentences => {
            dispatch(actions.setSelectedSentences(selectedSentences));
        },
        changeShowBack: showBack => {
            dispatch(actions.patchAppState({ showBack }));
        },
        patchDebateStats: (debId, value) => {
            dispatch(actions.patchDebateStats(debId, value));
        },
        patchTree: tree => {
            dispatch(actions.patchTree(tree));
        },
        showSideMenu: () => {
            dispatch(actions.patchAppState({ showSideMenu: true }));
        },
        hideSideMenu: () => {
            dispatch(actions.patchAppState({ showSideMenu: false }));
        },
    };
};
