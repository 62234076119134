export default {
    DOMAIN: "http://localhost:3001",
    FIREBASE:
        "https://console.firebase.google.com/u/0/project/giro-2/database/giro-2/data/",
    LOG: false,
    SIGNIN_METHOD: "email",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyCxwehfGm-4n1EBGW7TzZJvwMaysjjzYAs",
        authDomain: "debdb-staging.firebaseapp.com",
        databaseURL: "https://debdb-staging.firebaseio.com",
        projectId: "debdb-staging",
        storageBucket: "debdb-staging.appspot.com",
        messagingSenderId: "956902697413",
        appId: "1:956902697413:web:7e9e533bfef38c5c",
    },
};
